import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

function GettingStarted() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Sticky Gradient Header with User Logic */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <h1 className="text-white font-bold text-2xl">Daily Bonus Dashboard</h1>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">
            Home
          </Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">
              Dashboard
            </Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">
                Log In
              </Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Main Content */}
      <main className="p-8 max-w-4xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-800 mb-6">
          Getting Started
        </h1>
        <p className="text-gray-700 mb-8">
          Welcome to the quick guide for starting your sweepstakes and social casino journey 
          in a strategic way. By following these steps, you’ll be well on your way to collecting 
          bonuses, tracking daily rewards, and maximizing your potential cash-outs responsibly. 
          This is Getting Started guide is to help you implement <Link to="/the-method" className="text-indigo-600 underline ml-1">The Method</Link> which we describe here. 
          Remember that these are for sweepstakes and gaming sites, not real money casinos. We will
          refer to these sweepstakes social gaming sites as casinos since it is easier.
        </p>

        {/* Step 1 */}
        <h2 className="text-xl font-bold text-indigo-600 mb-2">
          Step 1: Create Your Account &amp; Explore the Dashboard
        </h2>
        <p className="text-gray-700 mb-4">
          Begin by creating an account on this site (or view the dashboard as a guest). Once logged in, head to your <Link to="/dashboard" className="text-indigo-600 underline ml-1">Dashboard</Link> to
          view an organized list of top casinos. The dashboard provides key details 
          about each casino, including bonus schedules, community votes, and more.
        </p>

        {/* Step 2 */}
        <h2 className="text-xl font-bold text-indigo-600 mb-2">
          Step 2: Create a New Gmail Account
        </h2>
        <p className="text-gray-700 mb-4">
          Consider opening a fresh Gmail (or any email service) dedicated solely to these casino 
          promotions. This keeps your main inbox clean and makes it easier to manage 
          sign-up offers and daily promotional messages you’ll receive from the casinos. Many casinos
          alsogive an additional bonus for allowing email marketing on signup. 
        </p>

        {/* Step 3 */}
        <h2 className="text-xl font-bold text-indigo-600 mb-2">
          Step 3: Choose Your Casinos
        </h2>
        <p className="text-gray-700 mb-4">
          Use both this dashboard and additional online resources to learn about each 
          casino’s bonus structure, withdrawal requirements, and community reputation. 
          This ensures you focus your energy on the platforms with the best offers 
          and reputation. We sorted these casinos based on our estimation of their profitability,
          but you can reorder the dashboard as you like. 
        </p>

        {/* Step 4 */}
        <h2 className="text-xl font-bold text-indigo-600 mb-2">
          Step 4: Create an Account at Each Casino
        </h2>
        <p className="text-gray-700 mb-4">
          Use your newly created email address to sign up for each casino. Collect
          signup bonuses.
        </p>

        {/* Step 5 */}
        <h2 className="text-xl font-bold text-indigo-600 mb-2">
          Step 5: Complete KYC Verification
        </h2>
        <p className="text-gray-700 mb-4">
          KYC (Know Your Customer) verification is a standard process where the casino 
          confirms your identity (often via ID uploads and address checks). Completing 
          this step is important to unlock full functionality of the casino, including 
          withdrawals. Make sure your info is correct and consistent across all accounts. 
          This is a requirement for all casinos. Ensure that they use a reputable 3rd party KYC provider,
          such as jumio, veriff, or similar. If you are unsure about the data you are sending to the casino,
          remember to checkout the reputation of the sweepstakes site and parent company. 

        </p>

        {/* Step 6 */}
        <h2 className="text-xl font-bold text-indigo-600 mb-2">
          Step 6: Implement The Method
        </h2>
        <p className="text-gray-700 mb-4">
          Repeatedly take advantage of daily bonuses, optionally churn welcome offers and promotional 
          offers across multiple casinos. Use 'Speed Mode' to quicky visit and collect daily bonuses.
          Always practice responsible bankroll management and avoid chasing losses. 
        </p>

        <p className="text-gray-700 mt-8">
          By following these steps, you’ll be well on your way to systematically 
          gathering rewards, understanding casino offers, and cashing out potential 
          profits in a responsible manner. 
        </p>
      </main>

      {/* Footer (Updated to match Home.js) */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4 mt-auto">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="mb-2 md:mb-0">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link to="/signup" className="underline">
              Sign Up
            </Link>
            <Link to="/login" className="underline">
              Log In
            </Link>
            <Link to="/dashboard" className="underline">
              Dashboard
            </Link>
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default GettingStarted;