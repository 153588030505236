import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

/**
 * TheMethod Page
 * 
 * This page provides a comprehensive guide to collecting daily bonuses, 
 * churning welcome offers, and washing special deals from social casinos 
 * to turn a consistent profit. We assume a "profit-first" mindset, 
 * focusing on how to best exploit the promotions rather than simply playing for fun.
 */

function TheMethod() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">

      {/* Sticky Gradient Header with User Logic */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <h1 className="text-white font-bold text-2xl">Daily Bonus Dashboard</h1>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">Home</Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">Dashboard</Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">Log In</Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Main Content */}
      <main className="flex-grow p-6 max-w-4xl mx-auto space-y-6 text-gray-800">
        <h1 className="text-3xl font-extrabold text-gray-800 mb-6">The Method</h1>

        {/* Overview */}
        <section>
          <h2 className="text-xl font-semibold mb-4">Overview</h2>
          <p className="text-gray-700 mb-4">
            “The Method” is our step-by-step guide to turning social and sweepstakes casinos 
            into a profitable side hustle. We do this by systematically collecting 
            daily login bonuses, churning lucrative welcome promotions, and “washing” 
            special deals. From daily bonuses alone we can consistently make $20 to $30 a day
            just from clicking through sites and collecting. This is guide will show you how to 
            be strategic about how to maximize profit from the sweepstakes casinos. Sweepstakes casinos
            are not casinos, in order to legally operate they have to be 'sweepstakes' or 'social' gaming sites. 
            They are taking advantage of the law and we are taking advantage of them. Disclosure: We are not sponsored
            by any of these casinos and we are not endorsing any of these casinos.
            This is not financial advice, do not take this as professional financial advice. Also nothing is guaranteed,
            we break down the math and statistics, but results vary and we are not going to promise any exact results.
          </p>
        </section>

        {/* Why We Built The Dashboard */}
        <section>
          <h2 className="text-xl font-semibold mb-4">Why We Built The Dashboard</h2>
          <p className="text-gray-700 mb-4">
            We created the Daily Bonus Dashboard to make it simple for you to manage 
            dozens of social casinos all in one place. Logging in daily to around 
            40 casinos can take a while, but our dashboard keeps track of which 
            bonuses are ready, organizes everything by profitability tier, and 
            describes the highest RTP strategies for each platform. We also 
            help you log your spending and withdrawals which is crucial to responsibly
            profiting. Yes these are referral links, but many of them give you a sign up bonus
            as well as taking more money from the sweepstakes casinos.
          </p>
        </section>

        {/* Collecting Daily Bonuses: The Easiest $20–$30 a Day */}
        <section>
          <h2 className="text-xl font-semibold mb-4">
            Collecting Daily Bonuses: The Easiest $20–$30 a Day
          </h2>
          <p className="text-gray-700 mb-4">
            The simplest way to start raking in consistent money is by collecting 
            daily login rewards. Many casinos offer increasingly better bonuses 
            for each consecutive day you claim them, making it extra important 
            to be consistent. This takes a while, but if you are consistent it only takes
            10 minutes to earn $20 to $30 a day. This might not sound like much, but over
            time it adds up. It can take a while to reach withdrawal thresholds (sometimes like 100+ days),
            but if you are doing this for 50 casinos it really adds up. It might seem like only
            .25 cents here and there but over all of them it actually adds up. If you collected the dailies
            every day for a year and only the dailies you could make an extra $9,000+ a year from just the dailies and 10 minutes a day.
            It will take an hour or two signing up AND COMPLETING KYC VERIFICATION at each casino, but after that you are ready to go.
          </p>
        </section>

        {/* Churning Welcome Bonuses & Washing Special Deals */}
        <section>
          <h2 className="text-xl font-semibold mb-4">
            Churning Welcome Bonuses & Washing Special Deals
          </h2>
          <p className="text-gray-700 mb-4">
            Beyond daily rewards, casinos typically offer welcome bonuses 
            and recurring special deals. The key is to identify which ones are 
            profitable to “wash” meaning the free or promo currency can be transformed 
            into real, withdrawable money after meeting the wagering requirements. 
            “Churning” is the art of signing up for multiple casinos to snag all 
            those welcome freebies, and “washing” is using high-RTP games to meet 
            the rollover efficiently (described below). This is where significant
            profit can be made. Their goal is to take a loss on the welcome offer to suck
            you in and then take your money. We are going to make them take a loss on the welcome offer
            and not get sucked in. Many of these casinos will also offer occasional special offers
            that are also profitable to wash. Telling if a deal is worth it can be tricky, typically we only take the deal
            if it gives 20% or more in virtual currency compared than real currency. You can theoretically profit from
            worse deals, but sometimes it is not worth the time. We describe the best Churn Strategy for each casino
            in our blog. Churning each casino's welcome offer can earn you over $10,000 quicklyfrom doing no work yourself.

          </p>
        </section>

        {/* Redeemable vs. Non-Redeemable Funds & Washing */}
        <section>
          <h2 className="text-xl font-semibold mb-4">
            Redeemable vs. Non-Redeemable Funds & Washing
          </h2>
          <p className="text-gray-700 mb-4">
            When you get a bonus, typically it starts as non-redeemable or 
            promotional currency. You must wager it (sometimes multiple times) 
            before it becomes withdrawable. This process is “washing.” Even at 
            a high RTP (like 97%–99%), expect small losses as you play.
          </p>
          <p className="text-gray-700">
            Once your funds become fully redeemable, do not keep playing. 
            Continuing to wager is “overwashing,” which just erodes your balance 
            further. The moment you can redeem, withdraw those gains. That’s the 
            difference between “playing for fun” and actually executing The Method.
            Overwashing can look like "Just one more spin" or "Just one more hand", never do this. 
            Using autoplay after checking withdrawable balance is very helpful to avoiding this.
          </p>
        </section>

        {/* Best RTP Strategy */}
        <section>
          <h2 className="text-xl font-semibold mb-4">Best RTP Strategy</h2>
          <p className="text-gray-700 mb-4">
            Always choose the highest RTP games available in each casino— 
            typically the ones that pay back more on average. This improves 
            your odds of holding onto more bonus money after the required play. 
            Since there are so many casinos with so many games we have hunted 
            for the highest RTP strategy at each casino. We describe the best 
            strategy in the dashboard. Remember that all of these games are just 1s and 0s.
            There is also a lot of math and statistics used by casinos, all they need to do is
            provide an entertaining UI for the computer to tell you the result it has predetermined.
            Choosing the lowest denomination is the best strategy since it makes it so your return is closest
            to the actual RTP (remember, stats). 
          </p>
          <p className="text-gray-700">
            Remember: This is about turning a profit, not the adrenaline rush 
            of slots or table games. Your “fun” is in seeing the final deposit 
            actually land in your bank account not the UI the 'sweepstakes' casino uses.
          </p>
        </section>

        {/* Patience & Statistics */}
        <section>
          <h2 className="text-xl font-semibold mb-4">Patience & Statistics</h2>
          <p className="text-gray-700 mb-4">
            Even with the best RTP strategies, variance is real. Sometimes 
            you’ll luck out and complete the wash with a bigger chunk than 
            expected; other times you won't. Over time (law of large numbers), 
            it averages out to the rtp, so if you’re patient and follow The Method consistently, 
            you’ll see the rewards mount.
          </p>
          <p className="text-gray-700">
            This is purely a numbers game. Trust the process, track your 
            results, and watch as the house edge flips in your favor 
            because of the bonuses. 
          </p>
        </section>

        {/* Profit Mindset */}
        <section>
          <h2 className="text-xl font-semibold mb-4">Profit Mindset</h2>
          <p className="text-gray-700 mb-4">
            Our entire approach is about making money, not entertainment. 
            That means you should adopt a strict, disciplined view: 
            only play the highest EV offers, only do the minimal wagering required, 
            and cash out the second you’re able. For more insights into how 
            casinos try to manipulate your psyche and why you shouldn’t fall for it, 
            check out our <Link to="/casino-tricks" className="underline text-indigo-600">Casino Tricks</Link> page.
          </p>
          <p className="text-gray-700">
            Don’t let the flashy lights or “big wins” distract you. Keep 
            your eyes on the profits—and keep your daily routine efficient 
            so you can spend the rest of your time doing literally anything else.
            Get in, get out, and repeat. Set the game to autoplay and do some pushups,
            don't watch an animation.
          </p>
          <p className="text-gray-700">
            After a while if you have made a consistent profit you might get "promo banned" this is when the
            casino will block you from getting the daily bonuses and special offers by no longer showing them
            on your account. Take this event with pride.
          </p>
        </section>

        {/* Staying Safe */}
        <section>
          <h2 className="text-xl font-semibold mb-4">Staying Safe</h2>
          <p className="text-gray-700 mb-4">
            While we emphasize that The Method is a side hustle and not 
            traditional gambling, you’re still engaging with platforms 
            designed to lure you into spending more time and money. Stay 
            vigilant about your spending, set personal limits, and take 
            frequent breaks if you ever feel you’re losing control. 
            Remember, you’re here to extract money from them, not the other way around.
            The list of social casinos in the dashboard is just what we personally have used, we are not
            sponsored by any of them or endorsing any of them.
            Ensure that you trust the social casinos you decide to enter your information into by 
            doing your own research. 
          </p>
          <p className="text-gray-700">
            Make sure not to get bogged down by understanding too much how each of the sweepstakes casinos or games work.
            This can suck up an extraordinary amount of time if you are not careful. Be careful about spending too much time on this
            quick easy side hustle. You can always chase the extra dollar by playing a minigame or two, but understand when it is a waste of time.
          </p>
        </section>

        {/* Dedicated Credit Card */}
        <section>
          <h2 className="text-xl font-semibold mb-4">Dedicated Credit Card</h2>
          <p className="text-gray-700 mb-4">
            For any actual deposits you do make (like optional special offers 
            that are profitable), using a dedicated credit card is smart. We personally 
            love the Wells Fargo Active Cash Card because it gives you 2% 
            cash back on everything—including “gaming/sweepstakes” purchases. This 
            2% from a reputable bank with good security measures can get you a better edge as well.
            Plus, they currently offer a $200 reward when you spend $500 in 
            your first few months, yet another churnable deal. Using a single card just for these deals 
            also makes it easy to track how much you’ve spent, and seperates your spending on
            this hustle from your other spending. 
          </p>
        </section>

        {/* Taxes Suck */}
        <section>
          <h2 className="text-xl font-semibold mb-4">Taxes Suck</h2>
          <p className="text-gray-700 mb-4">
            Yes, the money you earn is generally taxable income. Keep records 
            of what you spend and what you withdraw, and be prepared to 
            handle this come tax season. We’re not tax experts, so if you 
            have questions, consult a qualified professional. The bottom line: 
            treat this like any other income source and assume you’ll owe 
            taxes on your profits. The best way to avoid taxes is to not make any money, 
            but we are not going to do that.
          </p>
        </section>

        {/* Conclusion */}
        <section>
          <h2 className="text-xl font-semibold mb-4">Conclusion</h2>
          <p className="text-gray-700 mb-4">
            The Method is straightforward: Collect daily freebies, churn 
            welcome bonuses, wash special offers, cash out ASAP, and repeat. 
            Use our dashboard to track your progress, identify profitable deals, 
            and maintain a strict profit mindset. If you want to actually 
            “play” at the casino, that’s your callbut just know that it’s 
            a slippery slope. Here, we’re all about that easy, consistent side income.
          </p>
          <p className="text-gray-700">
            Now, get out there and start collecting those daily bonuses. 
            A few minutes a day can turn into thousands of dollars a year if 
            you stay disciplined and follow The Method. Good luck!
          </p>
        </section>
      </main>

      {/* Footer (Updated to match Home.js) */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4 mt-auto">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="mb-2 md:mb-0">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link to="/signup" className="underline">
              Sign Up
            </Link>
            <Link to="/login" className="underline">
              Log In
            </Link>
            <Link to="/dashboard" className="underline">
              Dashboard
            </Link>
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>

    </div>
  );
}

export default TheMethod;