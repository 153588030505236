import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

/**
 * Home (Landing) Page - now laid out in a blog-centric style.
 *
 * This page serves as the main hub of the site, showcasing our latest posts,
 * categories, and funneling visitors to sign up for or log into the dashboard.
 * We reference major categories like "Casino Reviews," "Getting Started," "Casino Tricks",
 * "The Method," and "Terms" or "Glossary" for SEO-friendly content linking.
 */

function Home() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if user is logged in
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Header / Navigation */}
      <header className="w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <div className="text-white font-bold text-xl">
          Daily Bonus Dashboard
        </div>
        <nav className="space-x-4">
          {user ? (
            <>
              <Link to="/dashboard" className="text-white hover:text-gray-200">
                My Dashboard
              </Link>
            </>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">
                Log In
              </Link>
              <Link to="/signup" className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition">
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Hero Section */}
      <section className="bg-gray-200 border-b border-gray-200 p-8 text-center">
        <h1 className="text-4xl font-extrabold text-gray-800 mb-4">
          Welcome to the Daily Bonus Dashboard
        </h1>
        <p className="text-gray-700 max-w-3xl mx-auto text-lg">
          Your go-to hub for tips, tricks, reviews, and methods on navigating the world
          of sweepstake and social casinos. Explore our latest posts and resources to
          maximize daily bonuses—then sign up or log in to use our free tracking dashboard!
        </p>
        <div className="flex justify-center mt-8 space-x-4">
          <Link
            to="/signup"
            className="px-6 py-3 bg-indigo-600 text-white font-semibold rounded hover:bg-indigo-700 transition"
          >
            Get Started
          </Link>
          <Link
            to="/dashboard"
            className="px-6 py-3 bg-cyan-600 text-white font-semibold rounded hover:bg-cyan-700 transition"
          >
            View Dashboard
          </Link>
        </div>
      </section>

      {/* Dashboard Screenshot */}
      <section className="bg-white p-8 text-center">
        <img
          src="/dash-ss.png"
          alt="Daily Bonus Dashboard Screenshot"
          className="mx-auto border border-gray-300 shadow-md"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </section>

      {/* Intro Section (NEW) */}
      <section className="bg-white p-8">
        <div className="max-w-6xl mx-auto">
          <p className="text-gray-800 text-lg mb-4">
            Sweepstakes casinos are social gaming platforms that do not offer real money gameplay
            but instead offer redeemable currency as a bonus. They exploit this loophole 
            to operate where traditional online gambling isn’t allowed. 
            Essentially, they brand themselves as “sweepstakes” or “social” gaming sites, 
            which allows them to distribute prizes in a way that’s legally distinct from 
            standard casinos. If that sounds a bit tricky, it’s because it is—but if they’re 
            taking advantage of a loophole, we are too!
          </p>
          <p className="text-gray-800 text-lg mb-4">
            Our approach, what we call “The Method,” is simple: we gather up every 
            daily login bonus, occasionally choose the most profitable welcome deals, and then 
            “wash” whatever currency we snag so it becomes withdrawable. The key is 
            using the highest RTP strategies and never overwashing. This is exactly 
            why we built the Daily Bonus Dashboard—to keep track of all these 
            promotions so you can systematically claim them, minimize time spent, 
            and maximize how much money you walk away with. If you can invest about 10 
            minutes each day into collecting bonuses, there’s a consistent profit to be made.
            Accross 40 or so casinos, collecting the miniscule daily bonuses can add up to $20-$30 a 
            day and $9,000+ a year. We added Speed Mode within the dashboard to help you do this for free.
            Many of the links are referral links, but they can also give you a bonus for using them and help 
            support the site.
          </p>
        </div>
      </section>

      {/* Categories / Featured Sections */}
      <section className="p-8 max-w-6xl mx-auto">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">
          Explore Our Categories
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
          {/* The Method */}
          <div className="bg-white rounded shadow p-6 hover:shadow-lg transition">
            <h3 className="text-xl font-semibold mb-2 text-indigo-600">The Method</h3>
            <p className="text-gray-700 mb-4">
              Discover how to “wash” special offers and daily bonuses for a profit,
              turning negative EV games into a winning side hustle.
            </p>
            <Link
              to="/the-method"
              className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition font-semibold"
            >
              Read More
            </Link>
          </div>

          {/* Getting Started */}
          <div className="bg-white rounded shadow p-6 hover:shadow-lg transition">
            <h3 className="text-xl font-semibold mb-2 text-indigo-600">Getting Started</h3>
            <p className="text-gray-700 mb-4">
              New to sweepstake and social casinos? Start here to learn the basics,
              from signing up to collecting your first daily bonuses.
            </p>
            <Link
              to="/getting-started"
              className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition font-semibold"
            >
              Read More
            </Link>
          </div>

          {/* Casino Tricks */}
          <div className="bg-white rounded shadow p-6 hover:shadow-lg transition">
            <h3 className="text-xl font-semibold mb-2 text-indigo-600">Casino Tricks</h3>
            <p className="text-gray-700 mb-4">
              Things to look out for when playing sweepstake and social casinos.
            </p>
            <Link
              to="/casino-tricks"
              className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition font-semibold"
            >
              Read More
            </Link>
          </div>

          {/* Casino Reviews */}
          <div className="bg-white rounded shadow p-6 hover:shadow-lg transition">
            <h3 className="text-xl font-semibold mb-2 text-indigo-600">Casino Reviews</h3>
            <p className="text-gray-700 mb-4">
              Looks at popular sweepstake and social casinos,
              including key promotions, reliability, and our personal takes.
            </p>
            <Link
              to="/casino-reviews"
              className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition font-semibold"
            >
              Read Reviews
            </Link>
          </div>

          {/* Terms / Glossary */}
          <div className="bg-white rounded shadow p-6 hover:shadow-lg transition">
            <h3 className="text-xl font-semibold mb-2 text-indigo-600">Glossary of Terms</h3>
            <p className="text-gray-700 mb-4">
              Look up definitions of common gambling terms, from RTP
              to variance, playthrough, “washing,” and beyond.
            </p>
            <Link
              to="/glossary"
              className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition font-semibold"
            >
              Read Glossary
            </Link>
          </div>

          {/* FAQ */}
          <div className="bg-white rounded shadow p-6 hover:shadow-lg transition">
            <h3 className="text-xl font-semibold mb-2 text-indigo-600">FAQ</h3>
            <p className="text-gray-700 mb-4">
              Common questions and helpful answers for new and returning players.
            </p>
            <Link
              to="/faq"
              className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition font-semibold"
            >
              Read FAQ
            </Link>
          </div>

          {/* NEW: Blackjack Calculators */}
          <div className="bg-white rounded shadow p-6 hover:shadow-lg transition">
            <h3 className="text-xl font-semibold mb-2 text-indigo-600">Blackjack Calculators</h3>
            <p className="text-gray-700 mb-4">
              Our optimal strategy calculators for Gravity Live Blackjack
              and Blackjack Lucky Sevens.
            </p>
            <Link
              to="/blackjack-calculator"
              className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition font-semibold"
            >
              Use Calculators
            </Link>
          </div>

        </div>
      </section>

      {/* Blog Roll / Example Post Previews */}
      <section className="p-8 bg-gray-200">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">
            Latest Posts
          </h2>
          <div className="space-y-4">

            {/* New Blog Post: Profitability Rankings Explained */}
            <div className="bg-white rounded shadow p-4 hover:shadow-lg transition">
              <h3 className="text-xl font-semibold mb-2 text-indigo-600">
                Profitability Rankings Explained
              </h3>
              <p className="text-gray-700 mb-2">
                Dive into our detailed classification system for social casinos, from 
                Tier 1 to Tier 5, and see exactly which sites we consider the most (and least) profitable...
              </p>
              <Link
                to="/blog/profitability-rankings-explained"
                className="text-indigo-600 underline"
              >
                Read More
              </Link>
            </div>

            {/* Blog Post: Why RTP Matters */}
            <div className="bg-white rounded shadow p-4 hover:shadow-lg transition">
              <h3 className="text-xl font-semibold mb-2 text-indigo-600">
                Why RTP Matters in Social Casinos
              </h3>
              <p className="text-gray-700 mb-2">
                RTP (Return to Player) is a crucial factor in evaluating which
                casino game offers the best chance at profit. Learn how you can use
                RTP to maximize your daily bonus conversions...
              </p>
              <Link
                to="/blog/why-rtp-matters"
                className="text-indigo-600 underline"
              >
                Read More
              </Link>
            </div>

            {/* New Blog Post: Avoiding Common Mistakes */}
            <div className="bg-white rounded shadow p-4 hover:shadow-lg transition">
              <h3 className="text-xl font-semibold mb-2 text-indigo-600">
                Avoiding Common Mistakes
              </h3>
              <p className="text-gray-700 mb-2">
                Discover the most common pitfalls that hinder your profitability 
                when churning social casinos and how you can avoid them...
              </p>
              <Link
                to="/blog/avoid-common-mistakes"
                className="text-indigo-600 underline"
              >
                Read More
              </Link>
            </div>

            {/* New Blog Post: Calculated Expected Value */}
            <div className="bg-white rounded shadow p-4 hover:shadow-lg transition">
              <h3 className="text-xl font-semibold mb-2 text-indigo-600">
                Calculated EV: Playthrough Requirements, RTP, and Volatility
              </h3>
              <p className="text-gray-700 mb-2">
                Deep-dive into the math of expected value, from measuring house edges to factoring volatility,
                to see how you can systematically transform daily freebies into consistent returns...
              </p>
              <Link
                to="/blog/calculated-expected-value"
                className="text-indigo-600 underline"
              >
                Read More
              </Link>
            </div>

            {/* New Blog Post: Gravity Blackjack Optimal Strategy */}
            <div className="bg-white rounded shadow p-4 hover:shadow-lg transition">
              <h3 className="text-xl font-semibold mb-2 text-indigo-600">
                Gravity Blackjack Optimal Strategy
              </h3>
              <p className="text-gray-700 mb-2">
                Discover a fresh take on live blackjack with random side-bet multipliers,
                generous RTP, and unique “one-to-many” dealing that still gives you full control...
              </p>
              <Link
                to="/blog/gravity-blackjack-optimal-strategy"
                className="text-indigo-600 underline"
              >
                Read More
              </Link>
            </div>

          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4 mt-auto">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="mb-2 md:mb-0">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link to="/signup" className="underline">
              Sign Up
            </Link>
            <Link to="/login" className="underline">
              Log In
            </Link>
            <Link to="/dashboard" className="underline">
              Dashboard
            </Link>
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;