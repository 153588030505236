import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';

/**
 * Why RTP Matters in Social Casinos
 * 
 * This advanced UI blog post covers what RTP (Return to Player) means,
 * how it impacts daily bonus conversions, and how players can leverage high-RTP
 * methods in social casinos. We also provide links to the dashboard
 * and "The Method" to help visitors transform free daily bonuses into
 * profitable returns.
 */
function WhyRTPMatters() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">

      {/* Sticky Gradient Header with User Logic */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <div className="text-white font-bold text-xl">Daily Bonus Dashboard</div>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">Home</Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">Dashboard</Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">Log In</Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Hero / Header Section */}
      <header className="bg-gradient-to-r from-indigo-600 to-cyan-600 p-8 text-white">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-extrabold mb-4">Why RTP Matters in Social Casinos</h1>
          <p className="text-lg">
            Learn how Return to Player (RTP) can transform daily freebies into real cashouts—
            and why choosing high-RTP games is key to consistent profits.
          </p>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow">
        <div className="max-w-4xl mx-auto p-6 space-y-8">
          {/* Intro Section */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">Understanding RTP</h2>
            <p className="text-gray-700 leading-relaxed">
              <strong>Return to Player (RTP)</strong> is a percentage that indicates how much of
              the total money wagered on a game is paid back to players over time. For example,
              a game with a 96% RTP is expected to return $96 for every $100 wagered on average.
              Although short-term results vary, games with higher RTP are generally more favorable
              for players looking to stretch their daily bonuses or deposit offers further.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              Social casinos often feature a variety of slots, table games, and other
              gambling-related activities, each with its own RTP. By focusing your play
              on higher-RTP games, you maximize the potential to convert daily freebies
              into real money that can be withdrawn or reused for further bonus churning.
            </p>
          </section>

          {/* Linking to "The Method" */}
          <section className="bg-gray-50 rounded p-4 border">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">RTP &amp; The Method</h2>
            <p className="text-gray-700 mb-4 leading-relaxed">
              Curious how to pinpoint these high-RTP games? Our <Link to="/the-method" className="text-indigo-600 underline">Method</Link> page
              explains advanced strategies for “washing” promotional credits, including which 
              games historically offer the best returns. Combine these strategies with the
              insights gained from analyzing RTP to build a more consistent daily profit.
            </p>
            <Link
              to="/the-method"
              className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 font-semibold"
            >
              Explore The Method
            </Link>
          </section>

          {/* Using the Dashboard */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">Track Your Progress</h2>
            <p className="text-gray-700 leading-relaxed">
              Another key aspect of leveraging high RTP games is systematic tracking.
              Our <Link to="/dashboard" className="text-indigo-600 underline">Daily Bonus Dashboard</Link> helps you monitor
              which bonuses you’ve collected, how much you’ve earned, and where you’ve spent
              your funds. By observing where higher RTP leads to more consistent conversions,
              you can refine your approach for even better results.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              Whether you’re a seasoned player or a newcomer, make sure to check the RTP
              of any game before you play. By pairing high-RTP strategy with disciplined
              bankroll management, it’s possible to create a profitable side hustle from
              social casino freebies.
            </p>
          </section>

          {/* Advanced Conclusion */}
          <section className="py-6 border-t">
            <h2 className="text-2xl font-bold text-gray-800 mb-2">Conclusion</h2>
            <p className="text-gray-700 leading-relaxed">
              RTP isn’t just a number—it’s an invaluable metric for ensuring that you’re
              squeezing the most out of your daily bonuses. Combined with strategic approaches
              like those in our “Method,” a good understanding of RTP can boost your 
              long-term profitability from social casinos.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              Ready to put this into action? Sign up, log in, or visit our 
              <Link to="/dashboard" className="text-indigo-600 underline ml-1">Dashboard</Link>
              to start managing and maximizing your bonuses today.
            </p>
          </section>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4">
        <div className="max-w-4xl mx-auto text-center">
          <p className="mb-2">© {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.</p>
          <Link to="/" className="underline">
            Back to Home
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default WhyRTPMatters;