import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

/**
 * BlackjackCalculator
 */

const cardValues = ['2','3','4','5','6','7','8','9','10','J','Q','K','A'];

/**
 * Return numeric values for a card rank (e.g. "A" => [1,11], "K"=>[10], "8"=>[8]).
 * If card is blank or invalid, return null.
 */
function getCardNumericValues(card) {
  if (!card) return null;
  const c = card.toUpperCase().trim();
  if (!c) {
    return null;
  }
  if (['J','Q','K'].includes(c)) {
    return [10];
  }
  if (c === 'A') {
    return [1, 11];
  }
  const val = parseInt(c, 10);
  if (val >= 2 && val <= 10) {
    return [val];
  }
  return null; // invalid rank
}

/**
 * computeBestTotal(cards):
 *   Sums up a hand's best total, counting Aces as 1 or 11 to get the best non-busting total.
 *   Skips any blank cards.
 */
function computeBestTotal(cards) {
  let baseTotal = 0;
  let aceCount = 0;
  for (const card of cards) {
    if (!card || !card.trim()) {
      // Skip blank card
      continue;
    }
    const values = getCardNumericValues(card);
    if (!values) {
      // Invalid card
      return 0;
    }
    if (values.length === 2) {
      aceCount += 1;
      baseTotal += 1;
    } else {
      baseTotal += values[0];
    }
  }
  let bestTotal = baseTotal;
  for (let i = 0; i < aceCount; i++) {
    if (bestTotal + 10 <= 21) {
      bestTotal += 10;
    }
  }
  return bestTotal;
}

/**
 * getPossibleTotals(cards):
 *   Returns an array of possible totals (with Aces counted as 1 or 11) that are <=21,
 *   or if none are <=21, just the smallest total (bust).
 *   If 21 is an option, we'll just return [21].
 *   Skips any blank cards.
 */
function getPossibleTotals(cards) {
  let totals = [];
  let base = 0;
  let aceCount = 0;

  for (const card of cards) {
    if (!card || !card.trim()) {
      // Skip blank card
      continue;
    }
    const vals = getCardNumericValues(card);
    if (!vals) {
      // invalid card
      return [0];
    }
    if (vals.length === 2) {
      aceCount++;
      base += 1;
    } else {
      base += vals[0];
    }
  }

  // Build possible totals
  let currentTotal = base;
  totals.push(currentTotal);
  for (let i = 0; i < aceCount; i++) {
    let withEleven = currentTotal + 10;
    if (withEleven <= 21) {
      totals.push(withEleven);
      currentTotal = withEleven;
    }
  }

  // Sort ascending
  totals = Array.from(new Set(totals)).sort((a, b) => a - b);
  // If 21 is in totals, let's just return [21] so we don't see "11 or 21"
  if (totals.includes(21)) {
    return [21];
  }
  return totals;
}

/**
 * isPair - only the first two cards in the hand are considered for pairs.
 */
function isPair(cards) {
  if (cards.length < 2) return false;
  const c1 = getCardNumericValues(cards[0]);
  const c2 = getCardNumericValues(cards[1]);
  if (!cards[0] || !cards[1] || !c1 || !c2) {
    return false;
  }
  function unify(r) {
    const up = r.toUpperCase();
    if (['J','Q','K'].includes(up)) return 10;
    if (up === 'A') return 'A';
    return parseInt(up, 10);
  }
  return unify(cards[0]) === unify(cards[1]);
}

/**
 * isSoftTotal: returns true if there's at least one Ace counted as 11 in the best total
 * and best total <=21.
 */
function isSoftTotal(cards) {
  const best = computeBestTotal(cards);
  let baseTotal = 0;
  let aceCount = 0;
  for (const card of cards) {
    if (!card || !card.trim()) continue;
    const values = getCardNumericValues(card);
    if (!values) return false;
    if (values.length === 2) {
      aceCount++;
      baseTotal += 1;
    } else {
      baseTotal += values[0];
    }
  }
  return aceCount > 0 && (best === baseTotal + 10) && best <= 21;
}

/**
 * getBasicStrategyMove - multi-deck S17 approach (pairs, soft totals, hard totals).
 * If bestTotal is 21 => Stand.
 */
function getBasicStrategyMove(cards, dealerCard, hasHitAlready) {
  if (!cards || cards.length === 0) return '...';
  if (!dealerCard) return '...';

  // 10-Card Charlie check
  const realPlayerCards = cards.filter((c) => c && c.trim()); // exclude blank
  if (realPlayerCards.length >= 10 && computeBestTotal(realPlayerCards) <= 21) {
    return 'Win (10-Card Charlie)';
  }

  const dealerVals = getCardNumericValues(dealerCard);
  if (!dealerVals) return '...';
  const dealerUp = (dealerVals.length === 2) ? 11 : dealerVals[0];
  const playerTotal = computeBestTotal(realPlayerCards);

  if (playerTotal > 21) return 'Bust';
  if (playerTotal === 21) return 'Stand';

  // Check pairs (only consider first 2 real cards)
  if (realPlayerCards.length === 2 && isPair(realPlayerCards)) {
    return getPairStrategyMove(realPlayerCards, dealerUp);
  }
  // Check soft
  if (isSoftTotal(realPlayerCards)) {
    return getSoftStrategyMove(playerTotal, dealerUp, hasHitAlready);
  }
  // Hard
  return getHardStrategyMove(playerTotal, dealerUp, hasHitAlready);
}

/** Pair strategy */
function getPairStrategyMove(cards, dealerUp) {
  const c = cards[0].toUpperCase();
  let rank = 0;
  if (c === 'A') rank = 'A';
  else if (['J','Q','K'].includes(c)) rank = 10;
  else rank = parseInt(c, 10);

  switch (rank) {
    case 'A':
      return 'Split';
    case 10:
      return 'Stand';
    case 9:
      if (dealerUp >= 2 && dealerUp <= 9 && dealerUp !== 7) return 'Split';
      return 'Stand';
    case 8:
      return 'Split';
    case 7:
      if (dealerUp >= 2 && dealerUp <= 7) return 'Split';
      return 'Hit';
    case 6:
      if (dealerUp >= 2 && dealerUp <= 6) return 'Split';
      return 'Hit';
    case 5:
      if (dealerUp >= 2 && dealerUp <= 9) return 'Double';
      return 'Hit';
    case 4:
      if (dealerUp >= 5 && dealerUp <= 6) return 'Split';
      return 'Hit';
    case 3:
    case 2:
      if (dealerUp >= 2 && dealerUp <= 7) return 'Split';
      return 'Hit';
    default:
      return 'Hit';
  }
}

/** Soft total strategy */
function getSoftStrategyMove(playerTotal, dealerUp, hasHitAlready) {
  switch (playerTotal) {
    case 20:
      return 'Stand';
    case 19:
      if (!hasHitAlready && dealerUp === 6) return 'Double';
      return 'Stand';
    case 18:
      if (dealerUp === 2 || dealerUp === 7 || dealerUp === 8) return 'Stand';
      if (!hasHitAlready && (dealerUp >= 3 && dealerUp <= 6)) return 'Double';
      if (dealerUp === 9 || dealerUp === 10 || dealerUp === 11) return 'Hit';
      return 'Stand';
    case 17:
    case 16:
    case 15:
    case 14:
    case 13:
      if (!hasHitAlready && (dealerUp >= 4 && dealerUp <= 6)) return 'Double';
      return 'Hit';
    default:
      return 'Hit';
  }
}

/** Hard total strategy */
function getHardStrategyMove(playerTotal, dealerUp, hasHitAlready) {
  if (playerTotal <= 8) return 'Hit';
  if (playerTotal === 9) {
    if (!hasHitAlready && dealerUp >= 3 && dealerUp <= 6) return 'Double';
    return 'Hit';
  }
  if (playerTotal === 10) {
    if (!hasHitAlready && dealerUp >= 2 && dealerUp <= 9) return 'Double';
    return 'Hit';
  }
  if (playerTotal === 11) {
    if (!hasHitAlready) return 'Double';
    return 'Hit';
  }
  if (playerTotal === 12) {
    if (dealerUp >= 4 && dealerUp <= 6) return 'Stand';
    return 'Hit';
  }
  if (playerTotal >= 13 && playerTotal <= 16) {
    if (dealerUp >= 2 && dealerUp <= 6) return 'Stand';
    return 'Hit';
  }
  if (playerTotal >= 17) {
    return 'Stand';
  }
  return 'Hit';
}

function BlackjackCalculator() {
  const [user, setUser] = useState(null);

  /**
   * We'll store multiple hands in an array. Each hand is an object like:
   * {
   *   cards: ["",""], // array of card rank strings
   *   hasHitAlready: false
   * }
   */
  const [playerHands, setPlayerHands] = useState([
    { cards: ["", ""], hasHitAlready: false }
  ]);

  // Single dealer card
  const [dealerCard, setDealerCard] = useState("");

  // We'll store the recommended move for each hand in an array
  const [suggestions, setSuggestions] = useState(["..."]);

  // For display: e.g. "Dealer showing 6 vs. Hand #1: 21"
  function getDealerVsTotals() {
    const dVal = dealerCard || "(None)";
    const lineParts = [`Dealer showing ${dVal}`];
    const allHandsTotals = playerHands.map((hand, idx) => {
      const poss = getPossibleTotals(hand.cards);
      if (!poss || poss[0] === 0) {
        return `Hand #${idx+1}: 0`;
      }
      const valid = poss.filter(t => t <= 21);
      if (valid.length === 0) {
        // all bust, just show the smallest total
        return `Hand #${idx+1}: ${poss[0]} (BUST)`;
      }
      if (valid.length === 1) {
        return `Hand #${idx+1}: ${valid[0]}`;
      }
      return `Hand #${idx+1}: ${valid.join(" or ")}`;
    });
    lineParts.push("vs.");
    lineParts.push(allHandsTotals.join(" | "));
    return lineParts.join(" ");
  }

  // Re-evaluate suggestions each time the player hands or dealer card changes
  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  useEffect(() => {
    const newSuggestions = playerHands.map((hand) => {
      return getBasicStrategyMove(hand.cards, dealerCard, hand.hasHitAlready);
    });
    setSuggestions(newSuggestions);
  }, [playerHands, dealerCard]);

  /**
   * onCardChange: handle user selecting a card from the dropdowns
   */
  function onCardChange(handIndex, cardIndex, newValue) {
    const newHands = [...playerHands];
    newHands[handIndex] = {
      ...newHands[handIndex],
      cards: newHands[handIndex].cards.map((c, i) => i === cardIndex ? newValue : c)
    };
    setPlayerHands(newHands);
  }

  function onDealerChange(newVal) {
    setDealerCard(newVal);
  }

  /**
   * handleHit: adds a blank card to the specified hand and sets hasHitAlready = true
   */
  function handleHit(handIndex) {
    const newHands = [...playerHands];
    const hand = newHands[handIndex];
    hand.hasHitAlready = true;
    hand.cards = [...hand.cards, ""];
    setPlayerHands(newHands);
  }

  /**
   * handleSplit: splits the specified hand into 2 separate hands
   */
  function handleSplit(handIndex) {
    const newHands = [...playerHands];
    const handToSplit = newHands[handIndex];
    const c1 = handToSplit.cards[0];
    const c2 = handToSplit.cards[1];
    // Replace the old hand with two new hands:
    const newHand1 = {
      cards: [c1, ""],
      hasHitAlready: false
    };
    const newHand2 = {
      cards: [c2, ""],
      hasHitAlready: false
    };
    newHands.splice(handIndex, 1, newHand1, newHand2);
    setPlayerHands(newHands);
  }

  /**
   * handleReset: resets everything to a single hand of two blank cards, dealer blank
   */
  function handleReset() {
    setDealerCard("");
    setPlayerHands([{ cards: ["",""], hasHitAlready: false }]);
  }

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Sticky Gradient Header */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <h1 className="text-white font-bold text-2xl">Daily Bonus Dashboard</h1>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">Home</Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">
              Dashboard
            </Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">Log In</Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Main Content */}
      <main className="flex-grow p-6 max-w-4xl mx-auto space-y-6 text-gray-800">
        <h1 className="text-3xl font-extrabold text-gray-800 mb-4">Blackjack Strategy Calculator</h1>
        <p className="text-gray-700">
          Select your initial player cards and the dealer's upcard. 
          If the system advises "Hit," you can add more cards. 
          If the advice is "Split," you can split the hand into two separate hands.
          Use the reset button to start over.
        </p>

        {/* Dealer Card */}
        <section className="mt-6 p-4 border border-gray-200 rounded space-y-4">
          <h2 className="text-xl font-bold text-gray-800">Dealer Upcard</h2>
          <CardSelector cardValue={dealerCard} onChange={(val) => onDealerChange(val)} />
          {dealerCard && dealerCard.toUpperCase() === 'A' && (
            <div className="mt-2 text-red-600 font-bold">
              Do not accept insurance.
            </div>
          )}
        </section>

        {/* Player Hands */}
        <section className="mt-6 p-4 border border-gray-200 rounded space-y-4">
          <h2 className="text-xl font-bold text-gray-800">Player Hands</h2>
          {playerHands.map((hand, hIndex) => {
            const poss = getPossibleTotals(hand.cards);
            let bestTotalLabel = "";
            if (poss && poss[0] !== 0) {
              const valid = poss.filter(t => t <= 21);
              if (valid.length > 0) {
                bestTotalLabel = `: ${valid[valid.length - 1]}`; 
              } else {
                bestTotalLabel = `: ${poss[0]} (BUST)`;
              }
            }

            return (
              <div key={hIndex} className="p-4 border border-gray-100 rounded mb-4">
                <p className="font-semibold text-gray-700">
                  Hand #{hIndex+1}{bestTotalLabel}
                </p>
                {hand.cards.map((card, cIndex) => (
                  <div key={cIndex} className="flex items-center space-x-2 my-2">
                    <span>Card {cIndex+1}:</span>
                    <CardSelector
                      cardValue={card}
                      onChange={(val) => onCardChange(hIndex, cIndex, val)}
                    />
                  </div>
                ))}

                {/* Strategy advice for this hand */}
                <div className="mt-2">
                  <strong>Advice:</strong> {suggestions[hIndex] || '...'}
                </div>
                {suggestions[hIndex] === 'Hit' && (
                  <button
                    onClick={() => handleHit(hIndex)}
                    className="bg-indigo-600 text-white px-3 py-1 rounded hover:bg-indigo-700 transition font-semibold mr-2 mt-2"
                  >
                    Add Another Card
                  </button>
                )}
                {suggestions[hIndex] === 'Split' && (
                  <button
                    onClick={() => handleSplit(hIndex)}
                    className="bg-cyan-600 text-white px-3 py-1 rounded hover:bg-cyan-700 transition font-semibold mt-2"
                  >
                    Split
                  </button>
                )}
              </div>
            );
          })}
        </section>

        {/* Dealer vs. Totals Display */}
        <section className="p-4 border border-gray-200 rounded">
          <p className="font-semibold text-indigo-700">
            {getDealerVsTotals()}
          </p>
        </section>

        {/* Reset Button */}
        <section className="p-4">
          <button
            onClick={handleReset}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition font-semibold"
          >
            Reset
          </button>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4 mt-auto">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="mb-2 md:mb-0">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link to="/signup" className="underline">
              Sign Up
            </Link>
            <Link to="/login" className="underline">
              Log In
            </Link>
            <Link to="/dashboard" className="underline">
              Dashboard
            </Link>
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

/**
 * CardSelector:
 *  Renders a dropdown for the user to pick a card rank from the standard cardValues.
 */
function CardSelector({ cardValue, onChange }) {
  return (
    <select
      value={cardValue || ''}
      onChange={(e) => onChange(e.target.value)}
      className="p-2 border border-gray-300 rounded"
    >
      <option value="">(None)</option>
      {cardValues.map((cv) => (
        <option key={cv} value={cv}>
          {cv}
        </option>
      ))}
    </select>
  );
}

export default BlackjackCalculator;