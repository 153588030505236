import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

function FAQ() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Sticky Gradient Header with User Logic */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <h1 className="text-white font-bold text-2xl">Daily Bonus Dashboard</h1>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">Home</Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">Dashboard</Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">Log In</Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Main Content */}
      <main className="p-8 max-w-4xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-800 mb-6">Frequently Asked Questions</h1>

        {/* 1: Definition */}
        <section className="mb-8">
          <h2 className="text-xl font-bold text-indigo-600 mb-2">What are Social Casinos?</h2>
          <p className="text-gray-700 mb-4">
            Social casinos are online platforms or apps where you can play casino-style games 
            (like slots, poker, blackjack, and roulette) using virtual currencies rather than 
            real money. They focus heavily on the social aspects, often featuring chat functions, 
            friend lists, and leaderboards. While the primary currency has no real-world value, 
            some platforms utilize special sweepstakes coins or offer redemption options that 
            allow players to convert winnings into real prizes or cash.
          </p>
        </section>

        {/* 2: Legality */}
        <section className="mb-8">
          <h2 className="text-xl font-bold text-indigo-600 mb-2">Are Social Casinos Legal?</h2>
          <p className="text-gray-700 mb-4">
            Generally, social casinos are legal across most of the United States since no direct 
            monetary wagers are involved. For sweepstakes-based models, platforms comply with 
            “no purchase necessary” rules, giving players a way to acquire sweepstakes coins 
            for free (often via daily bonuses or mail-in requests). However, regulations differ 
            by state, so it’s important to check your local laws if you’re unsure.
          </p>
        </section>

        {/* 3: Sweepstakes vs. Social Casinos */}
        <section className="mb-8">
          <h2 className="text-xl font-bold text-indigo-600 mb-2">What Makes a Sweepstakes Casino Different?</h2>
          <p className="text-gray-700 mb-4">
            A sweepstakes casino operates under specific sweepstakes laws that allow players to 
            win real prizes without a direct purchase requirement. While both social casinos 
            and sweepstakes casinos use virtual currency, sweepstakes sites include a secondary 
            currency (often called “sweeps coins”) that can be legally redeemed for real cash or 
            gift cards. This “legal loophole” is possible because these coins are awarded freely, 
            or bundled as a promotion alongside the purchase of the primary non-redeemable currency.
          </p>
        </section>

        {/* 4: Making Money */}
        <section className="mb-8">
          <h2 className="text-xl font-bold text-indigo-600 mb-2">Can I Actually Make Real Money Playing Social Casinos?</h2>
          <p className="text-gray-700 mb-4">
            It depends on the specific platform. Pure social casinos typically don’t offer 
            real-money payouts, but sweepstakes casinos do. By collecting bonus sweeps coins, 
            participating in special offers, or purchasing gold coins that come with sweeps coins 
            as a free promotional bonus, you can accumulate redeemable currency. Once you have 
            enough, you can convert it into real money or prizes. This approach is sometimes 
            referred to as “churning” social casinos: systematically taking advantage of daily 
            offers, promotions, and bonus redemptions to maximize your potential returns.
          </p>
        </section>

        {/* 5: How Churning Works */}
        <section className="mb-8">
          <h2 className="text-xl font-bold text-indigo-600 mb-2">What is “Churning” and How Does it Work?</h2>
          <p className="text-gray-700 mb-4">
            “Churning” refers to leveraging daily login bonuses, promotional codes, and free or 
            discounted coin packages to repeatedly generate or “churn” redeemable sweeps coins. 
            By consistently playing only the highest-return games, and cashing out once thresholds 
            are met, players can potentially earn steady (though usually modest) real-money returns. 
            This method requires discipline, tracking, and a thorough understanding of each platform’s 
            rules and bonus structures.
          </p>
        </section>

        {/* 6: Currency Types */}
        <section className="mb-8">
          <h2 className="text-xl font-bold text-indigo-600 mb-2">What is the Difference Between Gold Coins and Sweeps Coins?</h2>
          <p className="text-gray-700 mb-4">
            Typically, “Gold Coins” or “GC” are used strictly for entertainment and have no real-world 
            value. “Sweeps Coins” (or a similar name) can be redeemed for prizes, including real money. 
            Many sweepstakes casinos let you purchase Gold Coins and give you Sweeps Coins as a free 
            bonus. The key to earning real money is to accumulate and redeem Sweeps Coins, while Gold 
            Coins are primarily for fun, practice, or extended play sessions.
          </p>
        </section>

        {/* 7: Age & Eligibility */}
        <section className="mb-8">
          <h2 className="text-xl font-bold text-indigo-600 mb-2">Do I Need to Be a Certain Age or Live in a Certain State?</h2>
          <p className="text-gray-700 mb-4">
            Most social casinos require players to be at least 18 years old, but some states or 
            platforms have higher age requirements (like 21). While social casinos are generally 
            available in most U.S. states, a few jurisdictions—such as Washington and Idaho—may 
            have restrictions on sweepstakes-based play. Always check your platform’s terms of 
            service and local regulations.
          </p>
        </section>

        {/* 8: Withdrawals & KYC */}
        <section className="mb-8">
          <h2 className="text-xl font-bold text-indigo-600 mb-2">How Do I Get Paid and What is KYC?</h2>
          <p className="text-gray-700 mb-4">
            After you’ve accrued a certain amount of sweeps coins or redeemable currency, 
            most sweepstakes casinos allow you to request a payout via bank transfer, check, 
            PayPal, or other methods. Platforms typically require you to complete KYC (Know 
            Your Customer) verification, providing proof of identity and address, before 
            processing any withdrawals. This step helps prevent fraud and underage play.
          </p>
        </section>

        {/* 9: How to Tell if a Deal is Washable for Profit */}
        <section className="mb-8">
          <h2 className="text-xl font-bold text-indigo-600 mb-2">How to Tell if a Deal is Washable for Profit</h2>
          <p className="text-gray-700 mb-4">
            Determining if a special offer is worth “washing” (i.e., converting bonus or promotional 
            currency into redeemable cash) generally depends on the deal’s effective discount or 
            bonus rate. As a rough guideline, many experienced players suggest focusing on deals 
            that provide at least a 30% boost over the normal rate. For example, paying $70 for 
            $100 in game currency effectively gives you a $30 “bonus,” which is about a 30% discount 
            on the usual price. If deals dip below this threshold, the house edge and time it takes to wash, makes it less likely to be 
            profitable. Always remember to consider the playthrough requirements, the specific 
            casino’s RTP games, and your own risk tolerance before deciding whether to wash a deal.
          </p>
        </section>

        {/* 10: Differences from Real-Money Casinos */}
        <section className="mb-8">
          <h2 className="text-xl font-bold text-indigo-600 mb-2">How Do Social Casinos Compare to Real-Money Online Casinos?</h2>
          <p className="text-gray-700 mb-4">
            Social casinos provide the fun of casino gaming without the same financial 
            risk because the primary currency holds no monetary value. Sweepstakes casinos 
            add a unique twist by offering real rewards through free or promotional 
            sweepstakes coins. In contrast, real-money online casinos require deposits 
            and usually hold a gaming license in specific jurisdictions. Each model has 
            its pros and cons, but social casinos tend to focus more on casual, low-pressure 
            entertainment while still offering players a path to potential cash prizes.
          </p>
        </section>

        <p className="text-sm text-gray-500 italic">
          Disclaimer: This FAQ is for informational purposes only and does not constitute 
          legal or financial advice. Always check local laws and regulations before engaging 
          in any sweepstakes or gaming platforms.
        </p>
      </main>

      {/* Footer (Updated to match Home.js) */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4 mt-auto">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="mb-2 md:mb-0">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link to="/signup" className="underline">Sign Up</Link>
            <Link to="/login" className="underline">Log In</Link>
            <Link to="/dashboard" className="underline">Dashboard</Link>
            <Link to="/privacy-policy" className="underline">Privacy Policy</Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default FAQ;