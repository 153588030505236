import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

/**
 * CasinoTricks
 * 
 * This page provides an in-depth look at various strategies (and potential pitfalls) 
 * for churning social and sweepstakes casinos. We focus on psychological hooks, 
 * loyalty programs, and common methods to maximize daily bonuses—but also highlight 
 * red flags and risks to help players make informed decisions.
 */

function CasinoTricks() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Sticky Gradient Header with User Logic */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <h1 className="text-white font-bold text-2xl">Daily Bonus Dashboard</h1>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">
            Home
          </Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">
              Dashboard
            </Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">
                Log In
              </Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Main Content */}
      <main className="p-8 max-w-4xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-800 mb-6">
          Casino Tricks & Psychological Pitfalls
        </h1>
        <p className="text-gray-700 mb-6">
          While churning social casinos can be profitable, it also comes with pitfalls.
          Below, we discuss common tricks and psychological methods used by casinos 
          (and sometimes by players) to maximize or maintain engagement. Understanding 
          these tactics can help you stay in control of your play habits and finances.
        </p>

        <div className="space-y-6">
          <div>
            <h2 className="text-xl font-bold text-indigo-600">1. Loyalty Programs & VIP Tiers</h2>
            <p className="text-gray-700 mt-2">
              Many casinos offer loyalty rewards or VIP tiers, promising perks like bigger bonuses, 
              dedicated customer support, and special gifts. While these perks look like 
              investments that pay for themselves, they can encourage higher spending 
              over time as you strive to reach the next tier. This can create a 
              slippery slope: the desire to remain “special” often convinces players 
              to deposit more money than intended. Detecting danger signs involves 
              monitoring your motivation for playing—if you find yourself chasing 
              loyalty status rather than profiting, 
              consider stepping back and reviewing your bankroll management plan. Some VIP 
              programs can be gamed for profit, but others are not.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">2. Gamification & “Just One More Spin” Psychology</h2>
            <p className="text-gray-700 mt-2">
              Casinos frequently employ game design strategies such as progress bars, 
              leveling systems, and frequent micro-rewards. 
              These features trigger small dopamine hits, encouraging players to keep 
              going. A common tactic is letting you get “close” to a reward, nudging 
              you to keep playing until you’ve spent more. To detect this, track 
              how often you say, “Just one more try.” Remember what we are doing is taking advantage
              of statistics which requires fighting against our natural instincts. Only ever play 
              with unwashed in game currency, never redeemable currency.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">3. Sunk Cost Fallacy</h2>
            <p className="text-gray-700 mt-2">
              The more time or money you invest in a particular game or casino, 
              the harder it can be to walk away—even when the odds are no longer 
              in your favor. This is known as the sunk cost fallacy: people feel 
              compelled to continue in hopes of “getting their money’s worth.” 
              Recognize this by stepping back and asking: “Would I still chase 
              this if I hadn’t spent anything yet?” If the answer is no, it’s 
              time to cut your losses and walk away.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">4. Illusions of Control</h2>
            <p className="text-gray-700 mt-2">
              From selecting “lucky” numbers to timing slot spins, players often 
              believe they can influence random outcomes. Casinos encourage this 
              illusion by offering adjustable betting options or interactive 
              elements that feel skill-based but are ultimately chance. Check 
              yourself when you start thinking you’ve “figured out” a pattern 
              or method for guaranteed success. Always remember these games 
              are fundamentally designed to favor the house. These allusions of choice
              are a psychological trick to get you interested in the game. Remember that it
              is all ones and zeroes, the outcomes are instantly chosen, watching a prize wheel tick
              closer to the big payoff is just watching an animation of a predetermined outcome. 
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">5. Overconfidence & “Winning Streaks”</h2>
            <p className="text-gray-700 mt-2">
              A short run of good luck can inflate your confidence, leading to 
              bigger bets or extended play. Casinos highlight big wins and 
              celebrate them with on-screen effects or special prompts, 
              reinforcing the idea that you’re “on a roll.” Watch for overconfidence 
              when you deviate from your usual bet sizes or break your session 
              limits after a win. Setting strict budgets and session times 
              can help you avoid the temptation to chase that elusive hot streak. Remember
              that the game you are playing is just an engaging and fancy UI, the underlying math is what matters.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">6. Peer Jackpots and Big Wins</h2>
            <p className="text-gray-700 mt-2">
              Posting when other players win big is the one of the biggest tricks of all. It makes you upset that it wasnt you
              and makes you believe that it will be. We all think we are special and will be the next big winner. We play the lottery
              becuase the dream of a big win is worth it even though we know we will likely lose over time. Peers will also post their big wins, 
              but we never post our small incremental losses. If a casinopays someone out $1,000 but also makes 1,000 people lose $5 then the casino
              made $4,000 but all we see is the post of our friend winning $1,000. Never pay attention when someone else posts wins, they don't post losses.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">7. FOMO (Fear of Missing Out)</h2>
            <p className="text-gray-700 mt-2">
              Timed promotions, limited-time jackpots, and special events prey 
              on the fear of missing out. Players rush to take part, worrying 
              they’ll regret it if they skip the “big opportunity.” Some of these offers
              are worth taking advantage of, but never do so because you feel like you'll never
              get the deal again.
            </p>
          </div>
        </div>
      </main>

      {/* Footer (Updated to match Home.js) */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4 mt-auto">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="mb-2 md:mb-0">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link to="/signup" className="underline">
              Sign Up
            </Link>
            <Link to="/login" className="underline">
              Log In
            </Link>
            <Link to="/dashboard" className="underline">
              Dashboard
            </Link>
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default CasinoTricks;