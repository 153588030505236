import { supabase } from './supabaseClient';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

/**
 * Computes the next available bonus time for a given casino based on the reset_type and reset_value.
 * @param {string} last_collected - ISO date string of last time user collected.
 * @param {string} reset_type - "hourly" or "specific_time".
 * @param {string} reset_value - number of hours if hourly; HH:mm:ss if specific_time.
 * @returns {Date|null} Date object for next available bonus, or null if not applicable.
 */
function computeNextAvailable(last_collected, reset_type, reset_value) {
  if (!last_collected) return null;
  const last = new Date(last_collected);

  if (reset_type === 'hourly') {
    const hours = parseInt(reset_value, 10);
    return new Date(last.getTime() + hours * 3600 * 1000);
  } else if (reset_type === 'specific_time') {
    const [hh, mm, ss] = reset_value.split(':').map(Number);
    const year = last.getUTCFullYear();
    const month = last.getUTCMonth();
    const date = last.getUTCDate();

    const dailyResetUTC = new Date(Date.UTC(year, month, date, hh, mm, ss));
    if (last < dailyResetUTC) {
      return dailyResetUTC;
    } else {
      const tomorrowUTC = new Date(dailyResetUTC);
      tomorrowUTC.setUTCDate(tomorrowUTC.getUTCDate() + 1);
      return tomorrowUTC;
    }
  }

  return null;
}

/**
 * Converts a Date object for next available time into a string showing how many hours/minutes remain
 * until the bonus is ready. If 0 or negative time, returns "Ready".
 * @param {Date|null} next_available
 * @returns {string} Time until next bonus or 'Ready'.
 */
function formatTimeLeft(next_available) {
  if (!next_available) return 'Ready';
  const now = new Date();
  const diffMs = next_available - now;
  if (diffMs <= 0) return 'Ready';

  const totalMinutes = Math.floor(diffMs / 60000);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
}

/**
 * Helper function to get time in minutes until the bonus is next available. Helps with sorting by status.
 */
function timeInMinutesForStatus(entry) {
  const { casinos, last_collected } = entry;
  const nextAvail = computeNextAvailable(last_collected, casinos.reset_type, casinos.reset_value);
  if (!nextAvail) return 0;
  const diffMs = nextAvail - new Date();
  return diffMs <= 0 ? 0 : Math.floor(diffMs / 60000);
}

/**
 * Returns the "expected total reward" by multiplying daily reward by the best RTP (or 100% if not given).
 */
function getExpectedTotalReward(casino) {
  const daily = casino.expected_daily_reward || 0;
  const rtpFraction = (casino.rtp || 100) / 100;
  return daily * rtpFraction;
}

/**
 * Revised single-level sorting function for the user or visitor dashboard.
 * Sorts by the chosen column, then reverses if sortOrder is 'desc'.
 */
function sortEntries(entries, sortColumn, sortOrder, isVisitor) {
  const sorted = entries.slice().sort((a, b) => {
    let valA;
    let valB;

    switch (sortColumn) {
      case 'casino':
        valA = a.casinos.name.toLowerCase();
        valB = b.casinos.name.toLowerCase();
        return valA.localeCompare(valB);

      case 'status':
        valA = timeInMinutesForStatus(a);
        valB = timeInMinutesForStatus(b);
        return valA - valB;

      case 'expected_total_reward':
        valA = getExpectedTotalReward(a.casinos);
        valB = getExpectedTotalReward(b.casinos);
        return valA - valB;

      case 'last_collected':
        valA = a.last_collected ? new Date(a.last_collected).getTime() : 0;
        valB = b.last_collected ? new Date(b.last_collected).getTime() : 0;
        return valA - valB;

      case 'total_earned':
        valA = a.user_total_payout || 0;
        valB = b.user_total_payout || 0;
        return valA - valB;

      case 'total_spent':
        valA = a.user_total_spent || 0;
        valB = b.user_total_spent || 0;
        return valA - valB;

      case 'community_rank':
        valA = a.casinos.community_rank || 0;
        valB = b.casinos.community_rank || 0;
        return valA - valB;

      case 'tier':
        valA = isVisitor ? a.casinos.tier : (a.user_tier ?? a.casinos.tier);
        valB = isVisitor ? b.casinos.tier : (b.user_tier ?? b.casinos.tier);
        return valA - valB;

      default:
        return 0;
    }
  });

  if (sortOrder === 'desc') {
    sorted.reverse();
  }
  return sorted;
}

export default function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Notification permission state
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  // Tutorial state
  const [showTutorial, setShowTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(0);

  const [sortColumn, setSortColumn] = useState('casino');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showStrategyModal, setShowStrategyModal] = useState(false);
  const [strategyCasino, setStrategyCasino] = useState(null);
  const [timerTick, setTimerTick] = useState(0);

  // Resources panel state
  const [showResourcesPanel, setShowResourcesPanel] = useState(false);

  // Disclosure popup state
  const [showDisclosurePopup, setShowDisclosurePopup] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [isVisitor, setIsVisitor] = useState(false);

  // Dark mode state
  const [darkMode, setDarkMode] = useState(false);

  // Feedback feature states
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackType, setFeedbackType] = useState('feature_request');
  const [feedbackMessage, setFeedbackMessage] = useState('');

  // For drag-and-drop reordering
  const [draggedCasinoId, setDraggedCasinoId] = useState(null);

  // NEW STATE FOR MANUAL REORDER
  const [manualReorder, setManualReorder] = useState(false);

  // NEW STATE FOR SPEED MODE
  const [speedMode, setSpeedMode] = useState(false);

  useEffect(() => {
    // Load user preference from localStorage
    const isDark = localStorage.getItem('darkMode');
    if (isDark === 'true') {
      setDarkMode(true);
      document.documentElement.classList.add('dark');
    }
    loadData();
  }, [navigate]);

  // Keep the DOM and localStorage in sync with darkMode state
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('darkMode', 'true');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('darkMode', 'false');
    }
  }, [darkMode]);

  // Request notification permission on component mount
  useEffect(() => {
    if (typeof window !== 'undefined' && 'Notification' in window) {
      if (Notification.permission === 'granted') {
        setNotificationEnabled(true);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            setNotificationEnabled(true);
          }
        });
      }
    }
  }, []);

  // Update every minute to refresh countdown
  useEffect(() => {
    const interval = setInterval(() => {
      setTimerTick(t => t + 1);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  async function loadData(reason = 'login') {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) console.error(userError);

    let currentUser = user;
    if (!currentUser) {
      // Visitor mode
      setIsVisitor(true);
    }

    const { data: allCasinos, error: casinosError } = await supabase
      .from('casinos')
      .select('*');
    if (casinosError) console.error(casinosError);

    let mergedData = [];
    if (currentUser) {
      const { data: userCasinos, error: userCasinosError } = await supabase
        .from('casinos_users')
        .select('*')
        .eq('user_id', currentUser.id);
      if (userCasinosError) console.error(userCasinosError);

      mergedData = (allCasinos || []).map(casino => {
        const uc = userCasinos?.find(u => u.casino_id === casino.id);
        return {
          user_id: currentUser.id,
          casino_id: casino.id,
          last_collected: uc?.last_collected || null,
          user_total_payout: uc?.user_total_payout || 0,
          user_total_spent: uc?.user_total_spent || 0,
          user_vote: uc?.user_vote || 0,
          // Preserve the 5-tier grouping from DB
          user_tier: uc?.user_tier ?? casino.tier,
          // For drag-and-drop reordering, store user_rank, fallback to casino.rank if not set
          user_rank: uc?.user_rank ?? casino.rank ?? 9999,
          casinos: casino
        };
      });

      const totalSpent = mergedData.reduce((sum, entry) => sum + (entry.user_total_spent || 0), 0);

      // Removed problem gambling popup logic

      // *** FIX: Sort by user_rank so the displayed order matches DB rank from the start
      mergedData.sort((a, b) => (a.user_rank ?? 9999) - (b.user_rank ?? 9999));
      // Set manualReorder to true so we rely on the user_rank order upon first load
      setManualReorder(true);

    } else {
      // Visitor mode: No user data, just show casinos with no user-specific fields
      mergedData = (allCasinos || []).map(casino => ({
        user_id: null,
        casino_id: casino.id,
        last_collected: null,
        user_total_payout: 0,
        user_total_spent: 0,
        user_vote: 0,
        user_tier: casino.tier,
        user_rank: casino.rank ?? 9999,
        casinos: casino
      }));
    }

    setData(mergedData);
    setLoading(false);

    // Check tutorial completion for user mode only
    if (currentUser) {
      const tutorialCompleted = localStorage.getItem('tutorialCompleted');
      if (!tutorialCompleted) {
        setShowTutorial(true);
        setTutorialStep(0);
      }
    }
  }

  async function handleLogout() {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error(error);
    } else {
      navigate('/login');
    }
  }

  /**
   * Updates sorting and also syncs user_rank to match the new sorted order in DB if not visitor.
   */
  function onSortChange(col) {
    // If we sort by a column, revert manual reorder to false
    setManualReorder(false);

    let newSortOrder = 'asc';
    if (col === sortColumn) {
      newSortOrder = (sortOrder === 'asc') ? 'desc' : 'asc';
    }

    // Sort the data in memory
    const sortedData = sortEntries([...data], col, newSortOrder, isVisitor);

    // Now assign user_rank based on the new sorted order
    sortedData.forEach((item, index) => {
      item.user_rank = index + 1;
    });

    // Update local state with the newly sorted data
    setSortColumn(col);
    setSortOrder(newSortOrder);
    setData(sortedData);

    // If we are not in visitor mode, persist the new user_rank
    if (!isVisitor) {
      try {
        const updates = sortedData.map(d => ({
          user_id: d.user_id,
          casino_id: d.casino_id,
          user_tier: d.user_tier,
          user_rank: d.user_rank
        }));
        supabase
          .from('casinos_users')
          .upsert(updates, { onConflict: 'user_id,casino_id' })
          .then(({ error }) => {
            if (error) console.error('Error updating ranks after sort:', error);
          });
      } catch (err) {
        console.error('Unexpected error updating ranks after sort:', err);
      }
    }
  }

  async function handleCollected(userCasino) {
    // If visitor, skip DB update but still "collect" for the link
    if (isVisitor) {
      // Do nothing with Supabase
      return;
    }

    const { user_id, casino_id } = userCasino;
    const nowUTC = new Date().toISOString();
    const { error } = await supabase
      .from('casinos_users')
      .upsert({ user_id, casino_id, last_collected: nowUTC }, { onConflict: 'user_id,casino_id' });
    if (error) {
      console.error(error);
      return;
    }
    await loadData('collect');
  }

  async function handleCashoutSubmit(userCasino, amount) {
    if (isVisitor) {
      alert("Please sign up or log in to record cashouts.");
      return;
    }

    const { user_id, casino_id, user_total_payout } = userCasino;
    const newTotal = (user_total_payout || 0) + parseFloat(amount);
    const { error } = await supabase
      .from('casinos_users')
      .upsert({ user_id, casino_id, user_total_payout: newTotal }, { onConflict: 'user_id,casino_id' });
    if (error) {
      console.error(error);
      return;
    }
    await loadData('cashout');
  }

  async function handleSpentSubmit(userCasino, amount) {
    if (isVisitor) {
      alert("Please sign up or log in to record spending.");
      return;
    }

    const { user_id, casino_id, user_total_spent } = userCasino;
    const newTotal = (user_total_spent || 0) + parseFloat(amount);
    const { error } = await supabase
      .from('casinos_users')
      .upsert({ user_id, casino_id, user_total_spent: newTotal }, { onConflict: 'user_id,casino_id' });
    if (error) {
      console.error(error);
      return;
    }
    await loadData('spend');
  }

  async function handleVote(userCasino, newVote) {
    if (isVisitor) {
      alert("Please sign up or log in to vote.");
      return;
    }

    const { casinos, casino_id, user_id } = userCasino;
    const oldVote = userCasino.user_vote || 0;
    const oldRank = casinos.community_rank || 0;

    if (oldVote === newVote) {
      return;
    }

    let newRank = oldRank + (oldVote === 0 ? newVote : (newVote - oldVote));
    let { error: casinoError } = await supabase
      .from('casinos')
      .update({ community_rank: newRank })
      .eq('id', casino_id);
    if (casinoError) {
      console.error(casinoError);
      return;
    }

    let { error: userVoteError } = await supabase
      .from('casinos_users')
      .upsert({ user_id, casino_id, user_vote: newVote }, { onConflict: 'user_id,casino_id' });
    if (userVoteError) {
      console.error(userVoteError);
      return;
    }
    await loadData('vote');
  }

  function closeStrategyModal() {
    setShowStrategyModal(false);
    setStrategyCasino(null);
  }

  function handleDragStart(e, casinoId, tier) {
    setDraggedCasinoId(casinoId);
    e.dataTransfer.setData('fromTier', tier.toString());
    e.dataTransfer.setData('draggedCasinoId', casinoId.toString());
    e.dataTransfer.effectAllowed = 'move';
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  async function handleDrop(e, targetCasinoId) {
    e.preventDefault();
    if (!draggedCasinoId || isVisitor) return;

    const newData = [...data];
    const draggedIndex = newData.findIndex(d => d.casino_id === draggedCasinoId);
    const targetIndex = newData.findIndex(d => d.casino_id === targetCasinoId);

    if (draggedIndex === -1 || targetIndex === -1) return;

    const oldTier = newData[draggedIndex].user_tier;
    const newTier = newData[targetIndex].user_tier;

    // Remove dragged item
    const [removed] = newData.splice(draggedIndex, 1);

    // Re-locate index in case removal shifted it
    let newInsertIndex = newData.findIndex(d => d.casino_id === targetCasinoId);
    newData.splice(newInsertIndex, 0, removed);

    // If we changed tiers, update user_tier
    if (oldTier !== newTier) {
      removed.user_tier = newTier;
    }

    // Re-rank old tier
    let rankCounter = 1;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].user_tier === oldTier) {
        newData[i].user_rank = rankCounter++;
      }
    }

    // Re-rank the new tier
    rankCounter = 1;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].user_tier === newTier) {
        newData[i].user_rank = rankCounter++;
      }
    }

    setData(newData);

    try {
      const updates = newData.map(d => ({
        user_id: d.user_id,
        casino_id: d.casino_id,
        user_tier: d.user_tier,
        user_rank: d.user_rank
      }));
      const { error } = await supabase.from('casinos_users').upsert(updates, { onConflict: 'user_id,casino_id' });
      if (error) {
        console.error('Error updating user rank after drag-drop:', error);
      }
    } catch (err) {
      console.error('Unexpected error persisting reorder:', err);
    } finally {
      setDraggedCasinoId(null);
      setManualReorder(true);
    }
  }

  function handleDragEnd() {
    setDraggedCasinoId(null);
  }

  async function handleTierDrop(e, newTier) {
    e.preventDefault();
    if (!draggedCasinoId || isVisitor) return;

    const targetCasinoId = e.dataTransfer.getData('draggedOnRow');
    const newData = [...data];
    const draggedIndex = newData.findIndex(d => d.casino_id === draggedCasinoId);
    if (draggedIndex === -1) return;

    const oldTier = newData[draggedIndex].user_tier;
    if (oldTier === newTier) {
      return;
    }

    const [removed] = newData.splice(draggedIndex, 1);
    removed.user_tier = newTier;

    let insertIndex = 0;
    if (!targetCasinoId) {
      insertIndex = getEndOfTierIndex(newData, newTier);
    } else {
      const targetIndex = newData.findIndex(d => d.casino_id === parseInt(targetCasinoId, 10));
      if (targetIndex === -1) {
        insertIndex = getEndOfTierIndex(newData, newTier);
      } else {
        insertIndex = targetIndex;
      }
    }

    newData.splice(insertIndex, 0, removed);

    // Re-rank old tier
    let rankCounter = 1;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].user_tier === oldTier) {
        newData[i].user_rank = rankCounter++;
      }
    }

    // Re-rank new tier
    rankCounter = 1;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i].user_tier === newTier) {
        newData[i].user_rank = rankCounter++;
      }
    }

    setData(newData);

    try {
      const updates = newData.map(d => ({
        user_id: d.user_id,
        casino_id: d.casino_id,
        user_tier: d.user_tier,
        user_rank: d.user_rank
      }));
      const { error } = await supabase.from('casinos_users').upsert(updates, { onConflict: 'user_id,casino_id' });
      if (error) {
        console.error('Error updating user rank after tier-drop:', error);
      }
    } catch (err) {
      console.error('Unexpected error persisting tier-drop reorder:', err);
    } finally {
      setDraggedCasinoId(null);
      setManualReorder(true);
    }
  }

  function getEndOfTierIndex(array, tier) {
    let insertIndex = array.length;
    const newTierItems = array
      .map((it, idx) => ({ it, idx }))
      .filter(obj => obj.it.user_tier === tier);

    if (newTierItems.length > 0) {
      const lastNewTierIdx = newTierItems[newTierItems.length - 1].idx;
      insertIndex = lastNewTierIdx + 1;
    }
    return insertIndex;
  }

  const tutorialPages = [
    {
      title: "Welcome to the Daily Bonus Dashboard",
      content: (
        <div className="space-y-2">
          <p>
            This tool helps you keep track of the daily bonuses that each social or sweepstakes casino offers.
            By collecting your bonuses regularly, you can potentially turn them into real withdrawals.
          </p>
          <p>
            Throughout the rest of this tutorial, we’ll guide you on how to view and manage
            your casinos so that you never miss a daily freebie.
          </p>
        </div>
      )
    },
    {
      title: "Navigating the Dashboard",
      content: (
        <div className="space-y-2">
          <p>
            Each casino row shows when you can next collect a bonus. 
            'Ready' means you can collect now. Otherwise, it shows how 
            long until next availability. Use the strategy for maximizing 
            your returns. Clicking 'Collect' updates the Last Collected time to recalculate the Status
          </p>
          <p>
            Use the sorting feature (click on a column header) to order by different values.
            You can also reorder them with drag-and-drop in the rightmost column, or drag them to a different tier.
            You can also use the "Speed Collect" feature to automatically open and update the last collected time for all ready casinos. 
          </p>
        </div>
      )
    },
    {
      title: "Using the Resources Panel",
      content: (
        <div className="space-y-2">
          <p>
            In the Resources panel you can find links to our blog, and other resources.
          </p>
          <p>
            You can toggle the Dark Mode, delete your account, select notifications, and please give us feedback.
          </p>
        </div>
      )
    },
    {
      title: "Getting Started",
      content: (
        <div className="space-y-2">
          <p><strong>Step 1:</strong> Create a new email account to reduce spam.</p>
          <p><strong>Step 2:</strong> Choose sites and sign up with each site offering daily rewards. This requires KYC verification.</p>
          <p><strong>Step 3:</strong> Collect bonuses daily until reaching the minimum cashout threshold ($10-$100). Maximize earnings by choosing the best rtp strategy to convert your bonus into withdrawable funds.</p>
          <p><strong>Step 4:</strong> Cash out and record your earnings!</p>
          <p><strong>Step 5 (Optional):</strong> Profit from “washing deals.” Some deals to purchase in-game currency might be profitable with careful washing. Approach with caution and understand casino psychology and statistics first.</p>
          <p><strong>Step 6 (Optional):</strong> Checkout The Method and strategies for maximizing your daily earnings in our blog</p>
        </div>
      )
    },
    {
      title: "Recording Spends and Cash-outs",
      content: (
        <div className="space-y-2">
          <p>
            It’s important to record any real money you spend, as well as your total cash-outs,
            so you can keep track of your true profit or loss.
          </p>
          <p>
            Enter your spending or cash-out amounts directly in the row for each casino. We also use a
            separate card to help separate and organize your spending if you choose to churn the welcome offers.
          </p>
        </div>
      )
    },
    {
      title: "Thats it!",
      content: (
        <div className="space-y-2">
          <p>
            That’s the quick rundown! Now you can start collecting daily bonuses
            from each sweepstakes casino and watch your balance grow over time.
          </p>
          <p>
            Note: Yes these are referral links, but I do not endorse and am not sponsored by any of these casinos.
            You can choose not to use the referral links, but many of them have a bonus for you, and also helps support the site.
          </p>
        </div>
      )
    }
  ];

  function handleTutorialNext() {
    if (tutorialStep < tutorialPages.length - 1) {
      setTutorialStep(s => s + 1);
    } else {
      localStorage.setItem('tutorialCompleted', 'true');
      setShowTutorial(false);
    }
  }

  async function handleDeleteAccount() {
    setShowDeleteModal(false);

    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      console.error("No user logged in");
      return;
    }

    const response = await fetch('/api/deleteAccount', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: user.id })
    });

    if (response.ok) {
      await supabase.auth.signOut();
      navigate('/goodbye');
    } else {
      console.error("Failed to delete account");
    }
  }

  function handleResourcesClick() {
    setShowResourcesPanel(r => !r);
  }

  function onShowStrategy(casino) {
    setStrategyCasino(casino);
    setShowStrategyModal(true);
  }

  async function handleSendFeedback() {
    const { data: { user } } = await supabase.auth.getUser();
    let userId = user ? user.id : null;

    if (!feedbackMessage.trim()) {
      alert("Please enter a message before submitting.");
      return;
    }

    const { error } = await supabase
      .from('feedback')
      .insert({
        user_id: userId,
        feedback_type: feedbackType,
        message: feedbackMessage,
        created_at: new Date().toISOString(),
      });

    if (error) {
      console.error(error);
      alert("Error submitting feedback. Please try again later.");
    } else {
      alert("Thank you for your feedback!");
      setShowFeedbackModal(false);
      setFeedbackType('feature_request');
      setFeedbackMessage('');
    }
  }

  // Decide if we should use sorted data or user-defined (manual) ordering
  const now = new Date();
  let displayedData;
  if (manualReorder) {
    // Sort by user_rank or casino.rank if visitor
    displayedData = data.slice().sort((a, b) => {
      const rankA = isVisitor ? (a.casinos.rank ?? 9999) : (a.user_rank ?? 9999);
      const rankB = isVisitor ? (b.casinos.rank ?? 9999) : (b.user_rank ?? 9999);
      return rankA - rankB;
    });
  } else {
    displayedData = sortEntries(data, sortColumn, sortOrder, isVisitor);
  }

  // Helper to add commas to large numbers
  function formatCommas(num) {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  const totalCollected = data.reduce((sum, entry) => sum + (entry.user_total_payout || 0), 0);
  const totalSpent = data.reduce((sum, entry) => sum + (entry.user_total_spent || 0), 0);
  const totalProfit = totalCollected - totalSpent;

  // Summation for tiers 1..4 that are READY
  const totalAvailable = displayedData.reduce((sum, e) => {
    const effectiveTier = isVisitor ? e.casinos.tier : e.user_tier;
    if ([1,2,3,4].includes(effectiveTier)) {
      const nextAvail = computeNextAvailable(e.last_collected, e.casinos.reset_type, e.casinos.reset_value);
      if (!nextAvail || nextAvail <= now) {
        return sum + getExpectedTotalReward(e.casinos);
      }
    }
    return sum;
  }, 0);

  useEffect(() => {
    if (notificationEnabled && totalAvailable > 10) {
      new Notification("Daily Bonus Dashboard", {
        body: `You have $${totalAvailable.toFixed(2)} in daily bonuses ready!`
      });
    }
  }, [notificationEnabled, totalAvailable]);

  // Group by tier
  const groupByTier = {};
  displayedData.forEach(item => {
    const t = isVisitor ? item.casinos.tier : item.user_tier;
    if (!groupByTier[t]) {
      groupByTier[t] = [];
    }
    groupByTier[t].push(item);
  });

  const tiersToShow = Object.keys(groupByTier)
    .map(k => parseInt(k, 10))
    .filter(t => t >= 1 && t <= 5)
    .sort((a, b) => a - b);

  /**
   * Speed Collect: 
   * - We now have a checkbox group to let users pick which tiers to display (1..4).
   * - We show only casinos that are in those chosen tiers and are currently ready to collect (no future reset time).
   * - The "Collect" button is clickable even in visitor mode (opens link but no DB update).
   */
  function SpeedCollectView() {
    const [selectedTiers, setSelectedTiers] = useState([1, 2, 3, 4]);

    // Toggle a tier in the selectedTiers array
    function toggleTier(tier) {
      if (selectedTiers.includes(tier)) {
        setSelectedTiers(selectedTiers.filter(t => t !== tier));
      } else {
        setSelectedTiers([...selectedTiers, tier]);
      }
    }

    // Filter for only 'ready' casinos and tiers included in selectedTiers
    const readyCasinos = displayedData.filter((entry) => {
      const tier = isVisitor ? entry.casinos.tier : entry.user_tier;
      if (!selectedTiers.includes(tier)) {
        return false;
      }
      const nextAvail = computeNextAvailable(entry.last_collected, entry.casinos.reset_type, entry.casinos.reset_value);
      return !nextAvail || nextAvail <= now;
    });

    // Now sort by tier ascending, so tier 1 is on top, then tier 4 at the bottom
    const sortedReadyCasinos = readyCasinos.slice().sort((a, b) => {
      const tierA = isVisitor ? a.casinos.tier : a.user_tier;
      const tierB = isVisitor ? b.casinos.tier : b.user_tier;
      return tierA - tierB;
    });

    // Modified handleSpeedCollect so that visitors only open link, no DB update
    async function handleSpeedCollect(userCasino) {
      if (!isVisitor) {
        await handleCollected(userCasino);
      }
      window.open(userCasino.casinos.link, '_blank');
    }

    return (
      <div className="bg-white dark:bg-gray-800 p-4 rounded shadow-md mb-4">
        <h2 className="text-xl font-semibold mb-4 dark:text-gray-100">
          Speed Collect
        </h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">
          This view shows only casinos that are currently ready to be collected. You can pick which tiers (1–4) to display below. 
          Click "Collect" to mark them collected (if logged in) and open the referral link.
        </p>

        {/* TIER CHECKBOXES */}
        <div className="flex flex-wrap gap-4 mb-4">
          {[1, 2, 3, 4].map((tier) => (
            <label key={tier} className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={selectedTiers.includes(tier)}
                onChange={() => toggleTier(tier)}
              />
              <span className="text-gray-800 dark:text-gray-200 font-medium">
                Tier {tier}
              </span>
            </label>
          ))}
        </div>

        {/* If no casinos are available, display a big bold message */}
        {sortedReadyCasinos.length === 0 ? (
          <h2 className="text-2xl font-bold text-center text-green-600 mt-4">
            There is nothing left to collect!
          </h2>
        ) : (
          <div className="overflow-auto">
            <table className="mx-auto table-fixed w-auto bg-white dark:bg-gray-800 border rounded shadow-sm">
              <thead className="bg-gray-100 dark:bg-gray-700 border-b">
                <tr>
                  <th className="text-left font-semibold p-2 text-base w-40">Casino</th>
                  <th className="text-left font-semibold p-2 text-base w-32">Expected Daily</th>
                  <th className="text-left font-semibold p-2 text-base w-32">Collect & Visit</th>
                  <th className="text-left font-semibold p-2 text-base w-32">Strategy</th>
                </tr>
              </thead>
              <tbody>
                {sortedReadyCasinos.map((entry, idx) => {
                  const expTotal = getExpectedTotalReward(entry.casinos).toFixed(2);
                  return (
                    <tr
                      key={(entry.user_id || 'visitor') + '-' + entry.casino_id}
                      className={`border-b text-base ${
                        idx % 2 === 0 ? 'bg-white dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-700'
                      } hover:bg-gray-100 dark:hover:bg-gray-600`}
                    >
                      <td className="p-2 whitespace-nowrap">
                        {entry.casinos.name}
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        ${expTotal}
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <button
                          onClick={() => handleSpeedCollect(entry)}
                          className="text-white px-3 py-1 rounded transition bg-indigo-600 hover:bg-indigo-700"
                        >
                          Collect
                        </button>
                      </td>
                      <td
                        className="p-2 text-blue-600 dark:text-blue-400 hover:underline cursor-pointer whitespace-nowrap text-base font-medium px-3 py-1"
                        onClick={() => onShowStrategy(entry.casinos)}
                      >
                        View
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={`min-h-screen bg-gray-50 dark:bg-gray-900 p-4 text-gray-800 dark:text-gray-200 relative`}>
      {/* HEADER SECTION */}
      <div className="bg-white dark:bg-gray-800 p-4 rounded shadow-md mb-4 flex flex-col sm:flex-row sm:items-center sm:justify-between">
        <div>
          <div className="flex items-center space-x-4 mb-2 sm:mb-0">
            <h1
              onClick={() => setSpeedMode(false)}
              className={`text-3xl font-bold mb-2 cursor-pointer text-gray-700 dark:text-gray-300 ${
                !speedMode ? 'underline' : ''
              }`}
            >
              Your Dashboard
            </h1>
            <h1
              onClick={() => setSpeedMode(true)}
              className={`text-3xl font-bold mb-2 cursor-pointer text-gray-700 dark:text-gray-300 ${
                speedMode ? 'underline' : ''
              }`}
            >
              Speed Collect
            </h1>
          </div>

          {/* Display total stats only if not visitor */}
          {!isVisitor && (
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-start space-y-2 sm:space-y-0 sm:space-x-4 mt-2">
              <h2 className="text-xl font-semibold text-gray-700 dark:text-gray-300">
                Total Available Now: ${totalAvailable.toFixed(2)}
              </h2>
              <h2 className="text-xl font-semibold text-gray-700 dark:text-gray-300">
                Total Collected: ${formatCommas(totalCollected)}
              </h2>
              <h2 className="text-xl font-semibold text-gray-700 dark:text-gray-300">
                Total Spent: ${formatCommas(totalSpent)}
              </h2>
              <h2 className="text-xl font-semibold text-gray-700 dark:text-gray-300">
                Total Profit: ${formatCommas(totalProfit)}
              </h2>
            </div>
          )}
          {isVisitor && (
            <p className="text-gray-700 dark:text-gray-300 italic mt-2">
              Visitor Mode: Viewing casino info without login. Some actions disabled, but you can still open links.
            </p>
          )}
        </div>

        <div className="flex items-center space-x-2 mt-2 sm:mt-0">
          <button
            onClick={handleResourcesClick}
            className="bg-indigo-600 text-white px-4 py-1 rounded hover:bg-indigo-700 transition font-semibold"
          >
            Resources
          </button>
          {isVisitor ? (
            <button
              onClick={() => navigate('/signup')}
              className="bg-green-600 text-white px-4 py-1 rounded hover:bg-green-700 transition font-semibold"
            >
              Sign Up
            </button>
          ) : (
            <button
              onClick={handleLogout}
              className="bg-red-600 text-white px-4 py-1 rounded hover:bg-red-700 transition font-semibold"
            >
              Logout
            </button>
          )}
        </div>
      </div>

      {/* MAIN CONTENT */}
      {!speedMode && (
        <>
          {tiersToShow.map(t => {
            const tierRows = groupByTier[t];
            const headingText =
              t === 5
                ? "Tier 5: Not Recommended, Do Not Use!"
                : `Profitability Tier ${t}:`;

            return (
              <div
                key={t}
                onDragOver={handleDragOver}
                onDrop={(e) => handleTierDrop(e, t)}
                className="mb-4"
              >
                <h2 className="text-xl font-semibold my-4 dark:text-gray-200">{headingText}</h2>
                <UserCasinosTable
                  data={tierRows}
                  sortColumn={sortColumn}
                  sortOrder={sortOrder}
                  onSortChange={onSortChange}
                  onShowStrategy={onShowStrategy}
                  onCollected={handleCollected}
                  onCashoutSubmit={handleCashoutSubmit}
                  onSpentSubmit={handleSpentSubmit}
                  onVote={handleVote}
                  isVisitor={isVisitor}
                  handleDragStart={handleDragStart}
                  handleDragOver={handleDragOver}
                  handleDrop={handleDrop}
                  handleDragEnd={handleDragEnd}
                />
              </div>
            );
          })}
        </>
      )}

      {speedMode && <SpeedCollectView />}

      {showStrategyModal && strategyCasino && (
        <StrategyModal casino={strategyCasino} onClose={() => setShowStrategyModal(false)} />
      )}

      {showTutorial && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded shadow-2xl max-w-2xl w-full relative dark:bg-gray-800">
            <h2 className="text-2xl font-bold mb-4 dark:text-gray-200">{tutorialPages[tutorialStep].title}</h2>
            <div className="mb-6 text-gray-700 dark:text-gray-300">{tutorialPages[tutorialStep].content}</div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={handleTutorialNext}
                className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition font-semibold"
              >
                {tutorialStep < tutorialPages.length - 1 ? 'Next' : 'Finish'}
              </button>
            </div>
          </div>
        </div>
      )}

      {showDisclosurePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white dark:bg-gray-800 p-4 rounded shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-bold mb-2 text-gray-900 dark:text-gray-200">Disclosure</h2>
            <div className="text-gray-900 dark:text-gray-200 space-y-4">
              <p>Gambling, even just playing daily freebies, can turn into a slippery slope if you’re not careful.</p>
              <p>The idea is to have fun and take advantage of daily rewards and maybe earn a bit of extra cash. If you do end up spending money to take advantage of offers, understand that some of these links are referral links where I earn money if you spend. You do not need to use the referral links, but many of them have a bonus for you, and also take more money from the casinos.</p>
              <p>The strategies and the list of casinos are just what we personally use. We are not endorsing or sponsoring them. We are not responsible for their actions or your actions. This dashboard and strategies are NOT financial advice. No earnings are guaranteed.</p>
              <p>As for your data, we will never sell or share anything to any third party. Deleting your account deletes all data. Full privacy policy here: <a href="/privacy-policy" className="text-blue-600 underline dark:text-blue-400">Privacy Policy</a></p>
            </div>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDisclosurePopup(false)}
                className="bg-red-600 text-white px-4 py-1 rounded hover:bg-red-700 transition font-semibold"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {showResourcesPanel && (
        <div className="fixed top-20 right-4 bg-white dark:bg-gray-700 border dark:border-gray-600 shadow-lg rounded p-4 z-50 w-64">
          <div className="flex justify-between items-center mb-2">
            <h3 className="font-semibold text-gray-800 dark:text-gray-200">Resources</h3>
            <button onClick={() => setShowResourcesPanel(false)} className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 font-bold">X</button>
          </div>
          <ul className="space-y-2 text-sm">
            <li>
              <a href="/" className="text-blue-600 underline dark:text-blue-400">
                Daily Bonus Blog
              </a>
            </li>
            <li>
              <a
                href="https://www.ncpgambling.org/help-treatment/about-the-national-problem-gambling-helpline/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline dark:text-blue-400"
              >
                National Problem Gambling Helpline
              </a>
            </li>
            <li>
              <a
                href="/the-method"
                className="text-blue-600 underline dark:text-blue-400"
              >
                The Method
              </a>
            </li>
            <li>
              <button
                onClick={() => setShowDisclosurePopup(true)}
                className="text-blue-600 dark:text-blue-400 underline text-left w-full"
              >
                Disclosure
              </button>
            </li>
            <li>
              <button
                onClick={() => setShowDeleteModal(true)}
                className="text-blue-600 dark:text-blue-400 underline text-left w-full"
              >
                Delete Account
              </button>
            </li>
            <li>
              <button
                onClick={() => setShowFeedbackModal(true)}
                className="text-blue-600 dark:text-blue-400 underline text-left w-full"
              >
                Submit Feedback
              </button>
            </li>
            <li>
              <div className="flex items-center">
                <span className="mr-2 text-gray-800 dark:text-gray-200">Dark Mode</span>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={darkMode}
                    onChange={(e) => setDarkMode(e.target.checked)}
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-indigo-300
                  dark:peer-focus:ring-indigo-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full
                  peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px]
                  after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all
                  dark:border-gray-600 peer-checked:bg-indigo-600">
                  </div>
                </label>
              </div>
            </li>
          </ul>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded shadow-lg max-w-sm w-full dark:bg-gray-800">
            <h2 className="text-xl font-bold mb-2 text-gray-800 dark:text-gray-200">Delete Account</h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              Are you sure you want to delete your account and all associated data? This cannot be undone.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 text-gray-800 px-4 py-1 rounded hover:bg-gray-400 transition font-semibold dark:bg-gray-500 dark:text-gray-200 dark:hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteAccount}
                className="bg-red-600 text-white px-4 py-1 rounded hover:bg-red-700 transition font-semibold"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Feedback Modal */}
      {showFeedbackModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white dark:bg-gray-800 p-4 rounded shadow-lg max-w-sm w-full">
            <h2 className="text-xl font-bold mb-2 text-gray-800 dark:text-gray-200">Submit Feedback</h2>
            <label className="block mb-2 text-gray-700 dark:text-gray-200" htmlFor="feedbackType">
              Feedback Type
            </label>
            <select
              id="feedbackType"
              className="border border-gray-300 dark:border-gray-600 rounded w-full p-2 mb-3 text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-700"
              value={feedbackType}
              onChange={(e) => setFeedbackType(e.target.value)}
            >
              <option value="feature_request">Feature Request</option>
              <option value="report_casino">Report a Casino</option>
              <option value="report_inaccurate">Report Inaccurate Info</option>
              <option value="other">Other</option>
            </select>

            <label className="block mb-2 text-gray-700 dark:text-gray-200" htmlFor="feedbackMessage">
              Message
            </label>
            <textarea
              id="feedbackMessage"
              className="border border-gray-300 dark:border-gray-600 rounded w-full p-2 mb-3 text-gray-800 dark:text-gray-200 bg-white dark:bg-gray-700"
              rows="4"
              value={feedbackMessage}
              onChange={(e) => setFeedbackMessage(e.target.value)}
            />

            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setShowFeedbackModal(false)}
                className="bg-gray-300 dark:bg-gray-500 text-gray-800 dark:text-gray-200 px-4 py-1 rounded hover:bg-gray-400 dark:hover:bg-gray-600 transition font-semibold"
              >
                Cancel
              </button>
              <button
                onClick={handleSendFeedback}
                className="bg-indigo-600 text-white px-4 py-1 rounded hover:bg-indigo-700 transition font-semibold"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

/**
 * Table rendering component for user casinos.
 * Includes sorting, tooltips, collect/spend/cashout UI, etc.
 * Also handles row-based drag-and-drop to reorder user_rank (not user_tier).
 */
function UserCasinosTable({
  data,
  sortColumn,
  sortOrder,
  onSortChange,
  onShowStrategy,
  onCollected,
  onCashoutSubmit,
  onSpentSubmit,
  onVote,
  isVisitor,
  handleDragStart,
  handleDragOver,
  handleDrop,
  handleDragEnd
}) {

  const renderHeader = (label, columnKey, tooltip, sortable = true) => {
    const isActive = columnKey === sortColumn;
    const arrow = isActive ? (sortOrder === 'asc' ? '▲' : '▼') : '';
    const classes = `text-left font-semibold p-2 ${
      sortable ? 'cursor-pointer select-none' : ''
    } ${columnKey === 'casino' ? 'text-base' : 'text-sm'}`;

    return (
      <th
        className={classes}
        onClick={sortable ? () => onSortChange(columnKey) : undefined}
        title={tooltip || ''}
      >
        {label} {arrow}
      </th>
    );
  };

  return (
    <div className="overflow-auto">
      <table className="min-w-full bg-white dark:bg-gray-800 border rounded shadow-sm">
        <thead className="bg-gray-100 dark:bg-gray-700 border-b">
          <tr>
            {renderHeader(
              'Status',
              'status',
              'Time remaining until the next bonus is ready. "Ready" means you can collect now.',
              true
            )}
            {renderHeader(
              'Casino',
              'casino',
              'Sort by Casino name?',
              true
            )}
            <th className="text-left font-semibold p-2 text-sm" title="Casino referral link">
              Referral Link
            </th>
            {renderHeader(
              'Expected Daily',
              'expected_total_reward',
              'Estimated daily reward * RTP ^ Play Through Requirement = your approximate daily earnings',
              true
            )}
            <th className="text-left font-semibold p-2 text-sm" title="Collect your daily bonus">Collect</th>
            <th className="text-left font-semibold p-2 text-sm" title="Wash Game Field From DB">Wash Game</th>
            <th className="text-left font-semibold p-2 text-sm" title="View recommended strategy to convert your bonus profitably">Strategy</th>
            {renderHeader(
              'Last Collected',
              'last_collected',
              'When you last collected.'
            )}
            <th className="text-left font-semibold p-2 text-sm" title="Record a cashout amount">Cash-out Value</th>
            {renderHeader(
              'Total Earned',
              'total_earned',
              'Your total recorded cash-out from this casino'
            )}
            <th className="text-left font-semibold p-2 text-sm" title="Record spending amounts if you purchased currency">Spent</th>
            {renderHeader(
              'Total Spent',
              'total_spent',
              'Your total recorded spending for this casino'
            )}
            {renderHeader(
              'Community Score',
              'community_rank',
              'Overall rating from all users'
            )}
            <th className="text-left font-semibold p-2 text-sm" title="Drag to reorder (logged in users only)">
              Reorder
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((e, idx) => {
            return (
              <UserCasinoRow
                key={(e.user_id || 'visitor') + '-' + e.casino_id}
                rowIndex={idx}
                userCasino={e}
                onShowStrategy={onShowStrategy}
                onCollected={onCollected}
                onCashoutSubmit={onCashoutSubmit}
                onSpentSubmit={onSpentSubmit}
                onVote={onVote}
                isVisitor={isVisitor}
                handleDragStart={handleDragStart}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                handleDragEnd={handleDragEnd}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

/**
 * A row in the UserCasinosTable.
 */
function UserCasinoRow({
  rowIndex,
  userCasino,
  onShowStrategy,
  onCollected,
  onCashoutSubmit,
  onSpentSubmit,
  onVote,
  isVisitor,
  handleDragStart,
  handleDragOver,
  handleDrop,
  handleDragEnd
}) {
  const { casinos } = userCasino;
  const [cashoutValue, setCashoutValue] = useState('');
  const [spentValue, setSpentValue] = useState('');

  const nextAvail = computeNextAvailable(userCasino.last_collected, casinos.reset_type, casinos.reset_value);
  const displayStatus = formatTimeLeft(nextAvail);
  const expTotalReward = getExpectedTotalReward(casinos).toFixed(2);

  let lastCollectedString = 'Never';
  if (userCasino.last_collected) {
    const d = new Date(userCasino.last_collected);
    const dateString = d.toLocaleDateString();
    const timeString = d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    lastCollectedString = `${dateString} ${timeString}`;
  }

  const isReady = displayStatus === 'Ready';
  const baseRowColor = rowIndex % 2 === 0 ? 'bg-white dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-700';
  const readyHighlight = isReady
    ? 'bg-green-100 dark:bg-green-700 border-l-4 border-green-500 hover:bg-green-200 dark:hover:bg-green-600'
    : `${baseRowColor} hover:bg-gray-100 dark:hover:bg-gray-600`;

  const rowClassName = `${readyHighlight} border-b text-sm`;

  const statusCell = (
    <span className={`${isReady ? 'text-green-700 dark:text-green-200 font-semibold' : ''}`}>
      {displayStatus}
    </span>
  );

  function onRowDrop(e) {
    e.dataTransfer.setData('draggedOnRow', userCasino.casino_id.toString());
    e.stopPropagation();
    handleDrop(e, userCasino.casino_id);
  }

  return (
    <tr
      className={rowClassName}
      draggable={!isVisitor}
      onDragStart={(e) => handleDragStart(e, userCasino.casino_id, isVisitor ? casinos.tier : userCasino.user_tier)}
      onDragOver={(e) => handleDragOver(e)}
      onDrop={onRowDrop}
      onDragEnd={handleDragEnd}
    >
      <td className="p-2 whitespace-nowrap">
        {statusCell}
      </td>

      <td className="p-2 whitespace-nowrap text-base">
        {casinos.name}
      </td>

      <td className="p-2 whitespace-nowrap text-sm">
        <a
          href={casinos.link}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 underline hover:text-blue-800 text-base font-medium px-3 py-1" 
        >
          Visit
        </a>
      </td>

      <td className="p-2 whitespace-nowrap text-sm">
        ${expTotalReward}
      </td>

      <td className="p-2 whitespace-nowrap text-sm">
        <button
          onClick={() => onCollected(userCasino)}
          className={`text-white px-3 py-1 rounded transition ${
            isVisitor ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'
          }`}
          disabled={false /* no longer disabled in Speed Mode for visitor, but here it's the main table */}
        >
          Collect
        </button>
      </td>

      <td className="p-2 whitespace-nowrap text-sm">
        {casinos.wash_game || 'N/A'}
      </td>

      <td
        className="p-2 text-blue-600 dark:text-blue-400 hover:underline cursor-pointer whitespace-nowrap text-base font-medium px-3 py-1"
        onClick={() => onShowStrategy(casinos)}
      >
        View
      </td>

      <td className="p-2 whitespace-nowrap text-sm">{lastCollectedString}</td>

      <td className="p-2 whitespace-nowrap text-sm">
        <input
          className={`border p-1 w-20 rounded text-gray-800 dark:text-gray-200 ${
            isVisitor ? 'bg-gray-100 dark:bg-gray-500' : 'bg-white dark:bg-gray-600'
          }`}
          type="number"
          step="0.01"
          placeholder="0.00"
          value={cashoutValue}
          onChange={(e) => setCashoutValue(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && cashoutValue.trim() !== '') {
              await onCashoutSubmit(userCasino, cashoutValue.trim());
              setCashoutValue('');
            }
          }}
          disabled={isVisitor}
        />
      </td>

      <td className="p-2 whitespace-nowrap text-sm">${(userCasino.user_total_payout || 0).toFixed(2)}</td>

      <td className="p-2 whitespace-nowrap text-sm">
        <input
          className={`border p-1 w-20 rounded text-gray-800 dark:text-gray-200 ${
            isVisitor ? 'bg-gray-100 dark:bg-gray-500' : 'bg-white dark:bg-gray-600'
          }`}
          type="number"
          step="0.01"
          placeholder="0.00"
          value={spentValue}
          onChange={(e) => setSpentValue(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter' && spentValue.trim() !== '') {
              await onSpentSubmit(userCasino, spentValue.trim());
              setSpentValue('');
            }
          }}
          disabled={isVisitor}
        />
      </td>

      <td className="p-2 whitespace-nowrap text-sm">${(userCasino.user_total_spent || 0).toFixed(2)}</td>

      <td className="p-2 flex items-center space-x-2 whitespace-nowrap text-sm">
        <button
          onClick={() => onVote(userCasino, +1)}
          className={`font-bold text-lg ${
            userCasino.user_vote === 1 ? 'text-green-600 dark:text-green-400' : 'text-gray-600 dark:text-gray-300'
          } hover:text-green-700 dark:hover:text-green-500 transition ${isVisitor ? 'cursor-not-allowed opacity-50' : ''}`}
          disabled={isVisitor}
        >
          ▲
        </button>
        <span className="dark:text-gray-100">{casinos.community_rank || 0}</span>
        <button
          onClick={() => onVote(userCasino, -1)}
          className={`font-bold text-lg ${
            userCasino.user_vote === -1 ? 'text-red-600 dark:text-red-400' : 'text-gray-600 dark:text-gray-300'
          } hover:text-red-700 dark:hover:text-red-500 transition ${isVisitor ? 'cursor-not-allowed opacity-50' : ''}`}
          disabled={isVisitor}
        >
          ▼
        </button>
      </td>

      <td className="p-2 whitespace-nowrap text-gray-500 dark:text-gray-300 text-sm">
        {!isVisitor && (
          <span className="cursor-move text-xl" title="Drag to reorder">
            ☰
          </span>
        )}
      </td>
    </tr>
  );
}

function StrategyModal({ casino, onClose }) {
  const {
    where_to_collect,
    best_strategy,
    rtp,
    supports_crypto,
    bank_min,
    gift_min,
    crypto_min,
    id,
    currency,
    exchange_rate
  } = casino;

  const calcLink =
    best_strategy?.toLowerCase() === 'black jack'
      ? 'https://outplayed.com/blackjack-strategy-calculator'
      : best_strategy?.toLowerCase() === 'texas holdem'
      ? 'https://www.888poker.com/poker/poker-odds-calculator'
      : null;

  const withdrawalLines = [];
  if (id === 48) {
    withdrawalLines.push('Skrill: $100');
  } else {
    if (gift_min != null) withdrawalLines.push(`Gift Card: $${gift_min}`);
    if (bank_min != null) withdrawalLines.push(`Bank Transfer: $${bank_min}`);
    if (crypto_min != null) withdrawalLines.push(`Crypto: $${crypto_min}`);
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded max-w-md shadow-lg dark:bg-gray-800">
        <h2 className="font-bold text-xl mb-2 text-gray-800 dark:text-gray-200">Strategy for {casino.name}</h2>
        <p className="text-gray-700 dark:text-gray-300"><strong>How to find Reward:</strong> {where_to_collect}</p>
        <p className="text-gray-700 dark:text-gray-300"><strong>Best Strategy:</strong> {best_strategy}</p>
        <p className="text-gray-700 dark:text-gray-300"><strong>RTP of Best Strategy:</strong> {rtp}%</p>
        {supports_crypto && (
          <p className="text-gray-700 dark:text-gray-300">
            Supports crypto, use my coinbase affiliate link to earn an extra $10 on top of the sign up bonus{" "}
            <a
              href="https://coinbase.com/join/MQZK23H?src=referral-link"
              className="text-blue-600 underline dark:text-blue-400"
              target="_blank"
              rel="noreferrer"
            >
              Coinbase Referral
            </a>
          </p>
        )}
        {calcLink && (
          <p className="text-gray-700 dark:text-gray-300">
            Use this calculator for the best odds:{" "}
            <a
              href={calcLink}
              target="_blank"
              rel="noreferrer"
              className="text-blue-600 underline dark:text-blue-400"
            >
              Calculator
            </a>
          </p>
        )}

        {withdrawalLines.length > 0 && (
          <div className="mt-4">
            <p className="text-gray-700 dark:text-gray-300 font-semibold">Minimum Withdrawal:</p>
            {withdrawalLines.map((line, idx) => (
              <p key={idx} className="text-gray-700 dark:text-gray-300">{line}</p>
            ))}
          </div>
        )}

        {(currency || exchange_rate) && (
          <div className="mt-4">
            <p className="text-gray-700 dark:text-gray-300 font-semibold">Other Info:</p>
            {currency && (
              <p className="text-gray-700 dark:text-gray-300">Currency: {currency}</p>
            )}
            {exchange_rate && (
              <p className="text-gray-700 dark:text-gray-300">Currency Rate: {exchange_rate}</p>
            )}
          </div>
        )}

        <button
          onClick={onClose}
          className="bg-indigo-600 text-white px-4 py-1 mt-4 rounded hover:bg-indigo-700 transition"
        >
          Close
        </button>
      </div>
    </div>
  );
}