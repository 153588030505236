import React from 'react';
import { Link } from 'react-router-dom';

/**
 * CasinoReviews - Page that will host detailed reviews of various social/sweepstake casinos.
 * This serves as an example layout. Eventually, we will have about 50 casinos with in-depth analysis.
 * 
 * Updated to include an overview and link to the new Crown Coins Casino review page.
 */

function CasinoReviews() {
  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Header */}
      <header className="w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <div className="text-white font-bold text-xl">
          Daily Bonus Dashboard
        </div>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">
            Home
          </Link>
          <Link to="/dashboard" className="text-white hover:text-gray-200">
            Dashboard
          </Link>
        </nav>
      </header>

      {/* Main Content */}
      <main className="p-8 max-w-4xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-800 mb-6">
          Casino Reviews
        </h1>
        <p className="text-gray-700 mb-4">
          Below you'll find a collection of our reviews for various sweepstakes
          and social casinos. We'll evaluate their bonus structures, game variety,
          user experience, and more. Stay tuned as we add more reviews!
        </p>

        <div className="space-y-6">

          {/* Example Review 1 */}
          <article className="bg-gray-50 border border-gray-200 rounded p-4">
            <h2 className="text-xl font-bold text-indigo-600 mb-2">
              LuckyLand Slots
            </h2>
            <p className="text-gray-700 mb-2">
              LuckyLand Slots is known for its approachable interface and
              frequent bonus offers. This casino features a variety of slot
              themes, along with daily bonus spins to keep players engaged.
            </p>
            <p className="text-gray-700 text-sm italic">
              Review Score: 4.7 / 5 - Profitability Tier: 1
            </p>
          </article>

          {/* Example Review 2 */}
          <article className="bg-gray-50 border border-gray-200 rounded p-4">
            <h2 className="text-xl font-bold text-indigo-600 mb-2">
              Chumba Casino
            </h2>
            <p className="text-gray-700 mb-2">
              Chumba Casino is one of the most popular sweepstakes sites, offering
              a large selection of slot games, table games, and jackpots. Daily login
              bonuses and a robust community keep users coming back regularly.
            </p>
            <p className="text-gray-700 text-sm italic">
              Review Score: 4.8 / 5 - Profitability Tier: 1
            </p>
          </article>

          {/* New CrownCoinsCasino Overview */}
          <article className="bg-gray-50 border border-gray-200 rounded p-4">
            <h2 className="text-xl font-bold text-indigo-600 mb-2">
              Crown Coins Casino
            </h2>
            <p className="text-gray-700 mb-2">
              Crown Coins Casino is a rising sweepstakes platform recognized for its
              generous daily login rewards, two-currency system (Crown Coins and Sweeps Coins),
              and a substantial library of over 300 slot games. Ideal for players seeking
              frequent bonus opportunities and a straightforward user experience.
            </p>
            <p className="text-gray-700 text-sm italic mb-2">
              Review Score: 4.8 / 5 – Profitability Tier: 1
            </p>
            <Link to="/casino-reviews/crown-coins-casino" className="text-indigo-600 underline">
              Read Full Review
            </Link>
          </article>

        </div>
      </main>

      {/* Footer (Updated to match Home.js) */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4 mt-auto">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="mb-2 md:mb-0">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link to="/signup" className="underline">
              Sign Up
            </Link>
            <Link to="/login" className="underline">
              Log In
            </Link>
            <Link to="/dashboard" className="underline">
              Dashboard
            </Link>
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default CasinoReviews;