import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';

/**
 * Profitability Rankings Explained
 * 
 * This post provides an in-depth explanation of the five-tier profitability system used
 * to classify social casinos based on their daily freebies, special offers, welcome bonuses,
 * washability potential, and overall reputation. Each tier includes specific casinos
 * alongside detailed reasoning on their rank.
 */

function ProfitabilityRankingsExplained() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">

      {/* Sticky Gradient Header with User Logic */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <div className="text-white font-bold text-xl">Daily Bonus Dashboard</div>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">Home</Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">Dashboard</Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">Log In</Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Header Section */}
      <header className="bg-gradient-to-r from-indigo-600 to-cyan-600 p-8 text-white">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-extrabold mb-4">Profitability Rankings Explained</h1>
          <p className="text-lg">
            Dive into our detailed classification system for social casinos, ranging from
            highly reputable Tier 1 platforms to unreputable or near-impossible-profit Tier 5 brands.
            Learn how we determine each tier and which casinos fall under them. Note that this is not 
            financial advice, it is our analysis based on data and our research. Last updated 1/13/2025.
          </p>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow">
        <div className="max-w-4xl mx-auto p-6 space-y-8">
          {/* Introduction */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">Introduction</h2>
            <p className="text-gray-700 leading-relaxed">
              In the world of social and sweepstakes casinos, not all opportunities are created equal.
              Some casinos offer generous daily freebies, high-value promotions, and multiple ways
              to convert in-game currency into real-world cash. Others provide only modest returns,
              require heavy playthroughs, or come with questionable reputations. Our tier system
              serves as a quick reference to help you prioritize where to invest your time and effort.
              Below, we define each tier and list the casinos in that category. You can also visit
              our <Link to="/dashboard" className="text-indigo-600 underline">Dashboard</Link> to
              track daily freebies from these casinos efficiently. The dashboard also lists the best
              rtp strategy to profit from each of these casinos. 
            </p>
          </section>

          {/* Tier 1 */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">Profitability Tier 1</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              Tier 1 casinos are the cream of the crop: they consistently offer generous welcome
              bonuses, worthwhile daily freebies, and “washable” special offers that let you
              convert your in-game currency to cash with relatively low risk. These are highly
              reputable platforms known for timely withdrawals and fair RTP (Return to Player)
              options, so you can actually grow your bankroll over time.
            </p>
            <div className="bg-gray-100 rounded p-4 border">
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>Chumba Casino</li>
                <li>CrownCoinsCasino</li>
                <li>LuckyLand</li>
                <li>Modo</li>
                <li>Pulsz</li>
                <li>Pulsz Bingo</li>
                <li>Stake</li>
                <li>Zula</li>
              </ul>
            </div>
            <p className="text-gray-700 leading-relaxed mt-4">
              These casinos combine easy daily bonus collection with robust promotional offers that
              rarely require excessive playthrough. If you’re new to social casino churning, Tier 1
              platforms are a great starting point to build positive returns.
            </p>
          </section>

          {/* Tier 2 */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2 mt-8">Profitability Tier 2</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              Tier 2 casinos remain strong choices for daily freebies and generally good
              promotions, though they may have slightly less favorable terms or smaller
              welcome bonuses than Tier 1. They still provide a solid opportunity to turn a profit,
              but you might need a bit more patience to achieve meaningful returns.
            </p>
            <div className="bg-gray-100 rounded p-4 border">
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>McLuck</li>
                <li>Fortune Coins</li>
                <li>SpinPals</li>
                <li>Jackpota</li>
                <li>High 5</li>
                <li>Clubs Poker</li>
                <li>DingDingDing</li>
                <li>Kickr</li>
                <li>icasino</li>
                <li>WOW Vegas</li>
                <li>Hello Millions</li>
                <li>Punt</li>
                <li>Rolling Riches</li>
                <li>Spree</li>
                <li>Jefebet</li>
                <li>SportsMillions</li>
              </ul>
            </div>
            <p className="text-gray-700 leading-relaxed mt-4">
              While washability may not be quite as effortless here, you can still find
              valuable offers—particularly if you’re willing to jump on limited-time promotions or
              invest more effort into clearing playthrough requirements.
            </p>
          </section>

          {/* Tier 3 */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2 mt-8">Profitability Tier 3</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              Tier 3 casinos often provide a welcome bonus and always provide daily freebies, but they’re either
              smaller in scope or come with restrictive terms that can reduce your overall
              profit potential. Their special offers might be sporadically good but generally
              less reliable for consistent washing. Generally taking advantage of the welcome bonus 
              and collecting dailies till your first withdrawal is the only profitble strategy. Since they only take a
              a few clicks to collect they are still worth it.
            </p>
            <div className="bg-gray-100 rounded p-4 border">
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>Zoot</li>
                <li>Golden Hearts</li>
                <li>Vivaro</li>
                <li>Smiles</li>
                <li>MegaBonanza</li>
                <li>SpinBlitz</li>
                <li>Yay!</li>
                <li>PlayFame</li>
                <li>RealPrize</li>
                <li>Ruby Sweeps</li>
                <li>FunzCity</li>
                <li>Tao Fortune</li>
                <li>Stackr</li>
                <li>Chanced</li>
              </ul>
            </div>
            <p className="text-gray-700 leading-relaxed mt-4">
              Reaching withdrawal minimums may require more time and patience,
              but the earning potential adds up. 
            </p>
          </section>

          {/* Tier 4 */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2 mt-8">Profitability Tier 4</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              Tier 4 casinos tend to require a lot of patience. Their daily freebies aren’t
              especially large, and special promotions are rarely profitable enough to
              “wash” for guaranteed gains. At some, expect to play for well over 100 days to hit
              typical withdrawal thresholds if you’re relying on freebies alone. We still collect freebies
              from these casinos, but we don't recommend them for churning.
            </p>
            <div className="bg-gray-100 rounded p-4 border">
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>Global Poker</li>
                <li>Gold Slips</li>
                <li>LuckyHands</li>
                <li>Lucky Bird</li>
                <li>Moozi</li>
                <li>Sportzino</li>
                <li>Wild World</li>
                <li>SweepSlots</li>
                <li>Moonspin</li>
              </ul>
            </div>
            <p className="text-gray-700 leading-relaxed mt-4">
              These casinos aren’t entirely without merit if you’re willing to grind out freebies
              over the long haul. Just be aware that the slow accumulation process might not be
              worth your time unless you enjoy logging in daily for small incremental gains.
            </p>
          </section>

          {/* Tier 5 */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2 mt-8">Profitability Tier 5</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              Tier 5 casinos include either outright unreputable platforms or those with
              extremely low odds of turning a real profit. Whether it’s unreliable payouts,
              predatory terms, or minimal freebies that never justify the time investment,
              we advise caution and heavy research before spending any real or bonus money here.
            </p>
            <div className="bg-gray-100 rounded p-4 border">
              <ul className="list-disc list-inside text-gray-700 leading-relaxed">
                <li>Betcoin</li>
                <li>MyPrize</li>
                <li>Legendz</li>
                <li>MaxQuest</li>
                <li>The Money Factory</li>
                <li>Fortune Wheels</li>
                <li>GoldNLuck</li>
                <li>Playnomic</li>
                <li>Vegas Gems</li>
                <li>TheBoss.Casino</li>
              </ul>
            </div>
            <p className="text-gray-700 leading-relaxed mt-4">
              While there might be occasional stories of success, these casinos have enough
              red flags or lackluster returns that we don’t recommend them.
            </p>
          </section>

          {/* Conclusion */}
          <section className="py-6 border-t">
            <h2 className="text-2xl font-bold text-gray-800 mb-2">
              Final Thoughts
            </h2>
            <p className="text-gray-700 leading-relaxed">
              Our five-tier system is designed to help churning enthusiasts quickly pinpoint
              the most profitable sites while steering clear of questionable ones. Tiers 1
              and 2 are excellent foundations for consistent returns, Tier 3 can be worth your
              while if you have spare time to chase extra freebies, and Tier 4 is mostly for
              those who don’t mind long waits. Stay away from Tier 5.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              Always remember that your personal gaming preferences and risk tolerance
              should guide where you spend your time. If you decide to explore beyond Tier 1,
              keep track of your progress using our <Link to="/dashboard" className="text-indigo-600 underline">Dashboard</Link>
               and read up on advanced strategies like <Link to="/the-method" className="text-indigo-600 underline">The Method</Link>.
              Good luck, and may your daily freebies turn into steady, low-risk earnings!
            </p>
          </section>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4">
        <div className="max-w-4xl mx-auto text-center">
          <p className="mb-2">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <Link to="/" className="underline">
            Back to Home
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default ProfitabilityRankingsExplained;