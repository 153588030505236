import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';

/**
 * Gravity Blackjack Optimal Strategy (Deep-Dive, Technical Overview)
 *
 * Completely rewritten to present a purely technical, non-promotional resource
 * on Gravity Blackjack. This includes full details of the rules, game flow,
 * side bets, payouts, multipliers, and theoretical return to player.
 * The UI design has been updated to be more stylized and modern.
 */

function GravityBlackjackOptimalStrategy() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">

      {/* Sticky Header */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-700 via-purple-600 to-cyan-600 p-4 flex justify-between items-center shadow">
        <div className="text-white font-bold text-xl">
          Daily Bonus Dashboard
        </div>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">
            Home
          </Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">
              Dashboard
            </Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">
                Log In
              </Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Title Section */}
      <div className="bg-gradient-to-r from-indigo-700 via-purple-600 to-cyan-600 p-10 text-white text-center shadow-lg">
        <h1 className="text-4xl font-extrabold mb-4">Gravity Blackjack – Deep-Dive Technical Overview</h1>
        <p className="max-w-3xl mx-auto text-lg">
          Explore every key detail about Gravity Blackjack, from core rules to side bets and
          multiplier mechanics, all in one exhaustive resource.
        </p>
      </div>

      {/* Main Content */}
      <main className="flex-grow p-6 max-w-4xl mx-auto space-y-10 text-gray-800">

        {/* 1. Core Objective */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">1. Core Objective of Gravity Blackjack</h2>
          <p className="leading-relaxed mb-4">
            The fundamental goal in Gravity Blackjack aligns with classic Blackjack: reach a total
            closer to 21 than the dealer without exceeding 21. A natural Blackjack occurs when an Ace
            and a 10-value card form 21 in your first two cards.
          </p>
          <ul className="list-disc ml-5 space-y-2">
            <li>
              <strong>Compete Against the Dealer Only:</strong> Other players’ actions do not impact
              your results.
            </li>
            <li>
              <strong>Eight-Deck Setup:</strong> The game uses 8 standard 52-card decks, replaced once
              a certain portion has been dealt.
            </li>
          </ul>

          <div className="mt-6 p-4 bg-gray-50 border border-gray-200 rounded">
            <h3 className="font-bold mb-2">Blackjack Specifics</h3>
            <ul className="list-disc ml-5 space-y-1">
              <li><strong>Natural Blackjack:</strong> Ace + 10-value card pays 3:2.</li>
              <li><strong>Winning a Hand:</strong> If your total beats the dealer’s (21 or under), you win 1:1.</li>
              <li><strong>Bust:</strong> Exceeding 21 loses your bet immediately.</li>
              <li><strong>Push:</strong> Ties from 17–21 return the original stake.</li>
              <li>
                <strong>Dealer Requirements:</strong> Dealer hits through 16 and stands on 17 or more,
                including soft 17.
              </li>
            </ul>
          </div>
        </section>

        {/* 2. Card Values */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">2. Card Values and Hand Calculations</h2>
          <ul className="list-disc ml-5 space-y-2">
            <li><strong>2–10:</strong> Worth face value.</li>
            <li><strong>Jacks, Queens, Kings:</strong> Each counts as 10.</li>
            <li>
              <strong>Aces:</strong> Can be 1 or 11, whichever favors the hand total. A “soft” hand
              includes an Ace counted as 11.
            </li>
          </ul>
        </section>

        {/* 3. Key Player Options */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">3. Key Player Options</h2>

          <h3 className="text-xl font-semibold mb-2">3.1 Hit</h3>
          <p className="leading-relaxed mb-4">
            Choose <strong>Hit</strong> to draw an extra card. Keep hitting until you stand or bust.
          </p>

          <h3 className="text-xl font-semibold mb-2">3.2 Stand</h3>
          <p className="leading-relaxed mb-4">
            <strong>Stand</strong> to hold your total and end your turn. You draw no more cards.
          </p>

          <h3 className="text-xl font-semibold mb-2">3.3 Split</h3>
          <p className="leading-relaxed mb-4">
            If your first two cards share the same rank, you may split them into two hands by
            matching your initial bet. Each hand then proceeds independently, but you cannot re-split
            an already split hand. Splitting Aces yields only one additional card per Ace.
          </p>

          <h3 className="text-xl font-semibold mb-2">3.4 Insurance</h3>
          <p className="leading-relaxed mb-4">
            If the dealer’s upcard is an Ace, you can insure your bet for half your original stake.
            This pays 2:1 if the dealer actually has Blackjack, offsetting your main loss.
          </p>

          <h3 className="text-xl font-semibold mb-2">3.5 Double</h3>
          <p className="leading-relaxed mb-4">
            Doubling your bet grants exactly one more card before you must stand. You can also
            double after splitting, subject to the same one-card limit.
          </p>

          <h3 className="text-xl font-semibold mb-2">3.6 10 Card Charlie</h3>
          <p className="leading-relaxed">
            A rare scenario, but drawing 10 cards without busting automatically wins your hand,
            regardless of the dealer’s outcome.
          </p>
        </section>

        {/* 4. Game and Betting Flow */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">4. Game and Betting Flow</h2>
          <ol className="list-decimal ml-5 space-y-2">
            <li>
              <strong>Bet Placement:</strong> Put chips on the main bet and optionally on any of
              the four side bets.
            </li>
            <li>
              <strong>Timer:</strong> A countdown indicates how long you have to place wagers.
            </li>
            <li>
              <strong>No More Bets:</strong> Once time expires, bets are locked.
            </li>
            <li>
              <strong>Dealing:</strong> Two cards to the player area, two to the dealer (one face up,
              one face down).
            </li>
            <li>
              <strong>Player Decisions:</strong> Hit, Stand, Double, Split, or Insurance (if applicable).
              Inaction results in auto-Stand (≥12) or auto-Hit (≤11).
            </li>
            <li>
              <strong>Dealer Resolution:</strong> Dealer flips the hole card; hits through 16, stands on 17+.
            </li>
            <li>
              <strong>Outcome:</strong> Winnings are paid for main and side bets. Rebet or
              adjust wagers for the next round.
            </li>
          </ol>
        </section>

        {/* 5. Dealer Mechanics and Card Shuffling */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">5. Dealer Mechanics and Card Shuffling</h2>

          <h3 className="text-xl font-semibold mb-2">5.1 Dealer’s Hand</h3>
          <p className="leading-relaxed mb-4">
            The dealer only checks for Blackjack when their upcard is an Ace. If the hole card is
            a 10-value, the round concludes immediately with the dealer’s Blackjack.
          </p>

          <h3 className="text-xl font-semibold mb-2">5.2 Card Replacement and Burning</h3>
          <p className="leading-relaxed">
            New pre-shuffled decks come into play once a cut card appears. The dealer may burn a
            certain number of cards at the start of each shoe, especially if the revealed burn card
            is a 10 or face card (often burning 10 cards). When the cut card surfaces late in the shoe,
            the dealer finishes the current hand plus one more, then inserts a fresh set of decks.
          </p>
        </section>

        {/* 6. Side Bets Overview */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">6. Side Bets Overview</h2>
          <p className="leading-relaxed mb-4">
            Gravity Blackjack offers four optional side bets that are resolved independently and
            can receive multipliers between 2x and 10x.
          </p>

          {/* Suited Pair */}
          <div className="mb-6 p-4 bg-gray-50 border border-gray-200 rounded">
            <h3 className="text-xl font-semibold mb-2">6.1 Suited Pair</h3>
            <p className="leading-relaxed">
              Wager on your first two cards forming a pair. Types include Suited Pair,
              Colored Pair, and Mixed Pair. Multipliers can boost the highest payout from
              20:1 up to 200:1.
            </p>
          </div>

          {/* Poker 3 */}
          <div className="mb-6 p-4 bg-gray-50 border border-gray-200 rounded">
            <h3 className="text-xl font-semibold mb-2">6.2 Poker 3</h3>
            <p className="leading-relaxed">
              Combines your first two cards with the dealer’s upcard to form a 3-card
              poker hand (Flush, Straight, Three of a Kind, Straight Flush, or Suited Trips).
              Payouts can reach 1000:1 with a 10x multiplier.
            </p>
          </div>

          {/* Any 20 & Queens */}
          <div className="mb-6 p-4 bg-gray-50 border border-gray-200 rounded">
            <h3 className="text-xl font-semibold mb-2">6.3 Any 20 &amp; Queens</h3>
            <p className="leading-relaxed">
              Also known as Lucky Ladies, it pays for any two cards totaling 20 or
              containing Queens. The biggest payout is usually a matched pair of Queens
              of Hearts, up to 1000:1 with multipliers.
            </p>
          </div>

          {/* Dealer Bust */}
          <div className="p-4 bg-gray-50 border border-gray-200 rounded">
            <h3 className="text-xl font-semibold mb-2">6.4 Dealer Bust</h3>
            <p className="leading-relaxed">
              Bet on the dealer exceeding 21. The more cards it takes for the dealer to bust,
              the higher your payout (up to 3000:1 at a 10x multiplier).
            </p>
          </div>
        </section>

        {/* 7. Multipliers on Side Bets */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">7. Multipliers on Side Bets</h2>
          <p className="leading-relaxed">
            After betting closes and before dealing, one side bet (possibly none) is randomly
            assigned a multiplier between 2x and 10x. If you placed that side bet and it wins,
            your payout is boosted accordingly. This feature does not affect the main hand’s
            RTP or standard payouts.
          </p>
        </section>

        {/* 8. Table Limits and Payouts */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">8. Table Limits and Payouts</h2>

          <h3 className="text-xl font-semibold mb-2">8.1 Betting Limits</h3>
          <p className="leading-relaxed mb-4">
            Each table displays minimum and maximum bet sizes for both the main wager and
            the side bets. Consult your casino’s interface for exact limits.
          </p>

          <h3 className="text-xl font-semibold mb-2">8.2 Standard Main-Hand Payouts</h3>
          <ul className="list-disc ml-5 space-y-1 mb-4">
            <li><strong>Blackjack:</strong> 3:2</li>
            <li><strong>Winning Hand:</strong> 1:1</li>
            <li><strong>Insurance:</strong> 2:1 if the dealer has Blackjack</li>
            <li><strong>Push:</strong> Original stake returned</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">8.3 Side Bet Payout Ranges</h3>
          <p className="leading-relaxed">
            Multipliers can elevate these payouts by up to 10x:
          </p>
          <ul className="list-disc ml-5 space-y-1 mt-2">
            <li>Suited Pair: 20:1 → 200:1</li>
            <li>21+3 (Poker 3): 100:1 → 1000:1 (top-tier Suited Trips)</li>
            <li>Any 20 &amp; Queens: 100:1 → 1000:1 (e.g., Queen of Hearts pair)</li>
            <li>Dealer Bust: up to 300:1 → 3000:1 (8 or more cards bust)</li>
          </ul>
        </section>

        {/* 9. Theoretical Return to Player */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">9. Theoretical Return to Player (RTP)</h2>
          <p className="leading-relaxed mb-4">
            As provided by the developers:
          </p>
          <ul className="list-disc ml-5 space-y-2">
            <li>Main Blackjack Game: ~99.46%</li>
            <li>Suited Pair: ~95.95%</li>
            <li>Poker 3 (21+3): ~96.34%</li>
            <li>Dealer Bust: ~93.18%</li>
            <li>Any 20 &amp; Queens: ~94.66%</li>
          </ul>
          <p className="leading-relaxed mt-4">
            For the best odds, focus on the main hand. Side bets provide excitement but have lower RTP.
          </p>
        </section>

        {/* 10. Auto-Decision Policy */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">10. Auto-Decision Policy</h2>
          <ul className="list-disc ml-5 space-y-2 mb-4">
            <li>If your total is 11 or less, the system automatically hits if you don’t act.</li>
            <li>With 12 or more, it auto-stands if you fail to decide.</li>
          </ul>
          <p className="leading-relaxed">
            Disconnects or inactivity can trigger this policy, so stay alert to make optimal moves.
          </p>
        </section>

        {/* 11. Final Overview */}
        <section className="bg-white rounded-lg shadow p-6">
          <h2 className="text-2xl font-bold mb-4">11. Final Overview</h2>
          <p className="leading-relaxed mb-4">
            Gravity Blackjack follows familiar Vegas Blackjack principles but adds four optional
            side bets that can receive random multipliers (2x–10x) each round. Players seeking the
            highest theoretical returns should rely on basic strategy for the main hand. The side
            bets are more volatile yet offer significantly higher payouts when a multiplier kicks in.
            If you do nothing, auto-decision rules apply.
          </p>
          <p className="leading-relaxed">
            In short, Gravity Blackjack is an engaging variant that maintains a strong foundation
            of classic 21 while sprinkling in side-bet randomness for additional excitement.
            Armed with these technical details, you can approach the table fully informed.
          </p>
        </section>

      </main>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-indigo-700 via-purple-600 to-cyan-600 text-white p-4">
        <div className="max-w-4xl mx-auto text-center">
          <p className="mb-2">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <Link to="/" className="underline">
            Back to Home
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default GravityBlackjackOptimalStrategy;