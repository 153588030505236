import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

function PrivacyPolicy() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Sticky Gradient Header with User Logic */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <h1 className="text-white font-bold text-2xl">Daily Bonus Dashboard</h1>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">Home</Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">Dashboard</Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">Log In</Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Main content */}
      <main className="flex-grow p-6 max-w-4xl mx-auto space-y-6 text-gray-800">
        <h1 className="text-3xl font-extrabold text-gray-800 mb-6">Privacy Policy</h1>
        <h2 className="text-xl font-semibold mb-4">Last Updated: January 13 2025</h2>

        <p>
          Welcome to DailyBonusDashboard (“we,” “us,” or “our”). We respect your privacy and are
          committed to protecting your personal data. This Privacy Policy outlines how we collect,
          use, and protect your information when you access or use
          <a href="https://dailybonusdashboard.com" className="underline text-indigo-600"> https://dailybonusdashboard.com </a>
          (the “Website”) and any related services or applications (collectively, the “Service”).
        </p>

        <ol className="list-decimal list-inside space-y-4">
          <li>
            <strong>Information We Collect</strong>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                <strong>Account Information:</strong> 
                <ul className="list-disc list-inside ml-6 space-y-1">
                  <li><strong>Email Address:</strong> We collect your email address when you sign up for an account. Your email is used solely for login credentials and essential account features like password resets.</li>
                  <li><strong>Age:</strong> We collect your age to ensure that you are older than 18.</li>
                  <li><strong>State:</strong> We collect your state to ensure that you are not in a location where the sweepstake casinos are not allowed to operate.</li>
                </ul>
              </li>
              <li>
                <strong>Automatic Data:</strong> Cookies &amp; Similar Technologies: We may use cookies or similar tools 
                to improve the user experience (e.g., staying logged in). We do not track or collect any personal data beyond what’s essential 
                for the site’s functionality.
              </li>
              <li>
                <strong>No Other Personal Data:</strong> We do not collect sensitive information like your name, address, phone number, or payment details.
                We do not collect or store data about your gambling or financial activities beyond any voluntary notes you enter about spending or withdrawals in our dashboard.
              </li>
            </ul>
          </li>

          <li>
            <strong>How We Use Your Information</strong>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                <strong>Account Creation &amp; Management:</strong> We use your email to create and maintain your 
                account, verify your identity (login/logout), and provide you with the necessary functionality of our dashboard.
              </li>
              <li>
                <strong>Site Improvements &amp; Analytics:</strong> We may look at aggregated, non-personal data (like general usage stats) to
                improve our features and user experience. However, we do not link this data to individual users.
              </li>
              <li>
                <strong>Customer Support:</strong> If you reach out to us, we’ll use your contact info (email) and 
                any details you provide to help resolve your issue.
              </li>
            </ul>
          </li>

          <li>
            <strong>Sharing &amp; Disclosure of Your Data</strong>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                <strong>No Selling or Renting:</strong> We will never sell, rent, or trade your email address or any
                other personal information to third parties.
              </li>
              <li>
                <strong>Service Providers:</strong> In limited cases, we may use trusted third-party services
                (e.g., our cloud hosting, email provider, or database platform) to operate our Service. These providers may 
                have access to your email address only as necessary to perform services on our behalf and are contractually obligated 
                to keep your data confidential. 
              </li>
              <li>
                <strong>Legal Requirements &amp; Safety:</strong> We may disclose information if required by law or to
                protect our rights, your safety, or the safety of others.
              </li>
            </ul>
          </li>

          <li>
            <strong>Data Retention</strong>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                <strong>Account Data:</strong> We store your email and any dashboard-related info (e.g., your 
                casinos list, daily bonuses, personal notes) for as long as you maintain an account.
              </li>
              <li>
                If you delete your account, all your data is removed from our active systems. Please note that there
                may be a short window for backups or logs to cycle out, but your data will not be accessible or used in any way.
              </li>
            </ul>
          </li>

          <li>
            <strong>Cookies &amp; Similar Technologies</strong>
            <p>
              We might use cookies to remember your login session or preferences (like dark mode). These cookies do 
              not track you across other sites and are not shared with third parties for advertising or analytics. You can always clear or 
              block cookies in your browser settings, but doing so may affect certain features of our Service (e.g., staying logged in).
            </p>
          </li>

          <li>
            <strong>Data Security</strong>
            <p>
              We take precautions to protect your data. Your login credentials are encrypted, and we store all site 
              data using reputable third-party hosting services with industry-standard security measures. However, 
              no method of transmission over the Internet or electronic storage is 100% secure; we cannot guarantee absolute security.
            </p>
          </li>

          <li>
            <strong>Your Choices &amp; Rights</strong>
            <ul className="list-disc list-inside ml-4 space-y-2">
              <li>
                <strong>Access &amp; Correction:</strong> You can log into your account at any time to update your email 
                address or view your dashboard data.
              </li>
              <li>
                <strong>Deletion:</strong> If you want to delete your account, you can do so via the dashboard’s 
                settings or by contacting us. Once deleted, your data is removed from our active systems.
              </li>
              <li>
                <strong>Opt-Out of Communications:</strong> We do not send marketing or promotional emails. 
                If we ever do, we’ll provide an unsubscribe link or option.
              </li>
            </ul>
          </li>

          <li>
            <strong>Children’s Privacy</strong>
            <p>
              Our Service is intended for users who are 18 years of age or older (or the age of majority in your 
              jurisdiction). We do not knowingly collect personal information from minors. If we become aware that 
              we’ve collected personal info from someone under legal age, we will delete it promptly.
            </p>
          </li>

          <li>
            <strong>International Users</strong>
            <p>
              Our Service is operated in the United States. If you are accessing our Service from outside the U.S., 
              please be aware that your information may be transferred to and stored in servers located in the U.S. 
              or other jurisdictions. By using our Service, you consent to this transfer.
            </p>
          </li>

          <li>
            <strong>Changes to This Policy</strong>
            <p>
              We may occasionally update this Privacy Policy to reflect changes in our practices or for legal reasons. 
              We will post any modifications here and update the “Last Updated” date at the top. If the changes are 
              significant, we’ll provide a more prominent notice (e.g., via email or a banner notice on the website).
            </p>
          </li>

          <li>
            <strong>Contact Us</strong>
            <p>
              If you have questions about this Privacy Policy or our data practices, please contact us at
              <a href="mailto:dailybonusdashboard@gmail.com" className="underline text-indigo-600"> dailybonusdashboard@gmail.com</a>.
            </p>
          </li>
        </ol>
      </main>
    </div>
  );
}

export default PrivacyPolicy;