import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';

/**
 * Calculated Expected Value: Play-through requirement, RTP, and Volatility
 *
 * This post goes in-depth with the mathematical and economic foundation of
 * social casino churning, focusing on the interplay between RTP, volatility,
 * and how playthrough requirements impact your expected value.
 * Ideal for players seeking a deeper understanding of the statistics 
 * that drive social casino profitability strategies.
 */

function CalculatedExpectedValue() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">

      {/* Sticky Gradient Header with User Logic */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <div className="text-white font-bold text-xl">Daily Bonus Dashboard</div>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">Home</Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">Dashboard</Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">Log In</Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Header Section */}
      <header className="bg-gradient-to-r from-indigo-600 to-cyan-600 p-8 text-white">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-extrabold mb-4">
            Calculated Expected Value:
            <br className="hidden md:block" />
            Play-through, RTP, &amp; Volatility
          </h1>
          <p className="text-lg">
            Uncover the mathematics behind social casino profitability by dissecting
            playthrough requirements, house edges, and game volatility for a clearer
            picture of your true expected value.
          </p>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow">
        <div className="max-w-4xl mx-auto p-6 space-y-8">

          {/* Section 1: Introduction to Expected Value */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">1. Understanding Expected Value (EV)</h2>
            <p className="text-gray-700 leading-relaxed">
              In gambling (and economics), <strong>expected value (EV)</strong> represents the
              average outcome you can expect if a given scenario were to be repeated many times.
              Formally, for a discrete set of outcomes <em>x<sub>i</sub></em> with probabilities 
              <em>p<sub>i</sub></em>, EV is:
            </p>
            <pre className="bg-gray-100 p-4 rounded mt-2 text-gray-800">
              EV = Σ (x<sub>i</sub> * p<sub>i</sub>)
            </pre>
            <p className="text-gray-700 mt-4 leading-relaxed">
              For social casinos, you can think of “x<sub>i</sub>” as the net profit or loss from each 
              possible outcome, and “p<sub>i</sub>” as the probability of that outcome occurring.
              The higher your EV, the more likely you are to generate profits over many sessions—
              though short-term variance can still swing in either direction.
            </p>
          </section>

          {/* Section 2: RTP and the House Edge */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">2. RTP &amp; the House Edge</h2>
            <p className="text-gray-700 leading-relaxed">
              Return to Player (RTP) is effectively the long-term percentage of wagered funds 
              that a game pays back to players. A 95% RTP game is expected to keep 5% for the house 
              over the long run, which translates to a 5% house edge. Thus, the EV for any single 
              wager is:
            </p>
            <pre className="bg-gray-100 p-4 rounded mt-2 text-gray-800">
              EV<sub>single wager</sub> = (RTP) * (Wager) - (1 - RTP) * (Wager)
            </pre>
            <p className="text-gray-700 mt-4 leading-relaxed">
              Since <em>RTP + HouseEdge = 1</em>, if you continue to wager enough times, you
              effectively lose <em>HouseEdge × Wager</em> on average. However, in the context
              of social casinos, <strong>bonuses</strong> and <strong>promotional offers</strong>
              can offset this negative EV if they provide enough extra currency or
              “expected value boost” to cover the house edge and yield a net positive.
            </p>
          </section>

          {/* Section 3: The Math of Playthrough Requirements */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">3. Playthrough Requirements</h2>
            <p className="text-gray-700 leading-relaxed">
              When a bonus requires a certain playthrough (e.g., 10x), it means you must bet 
              10 times the bonus amount before it becomes redeemable. If the bonus is $100 and 
              the game’s RTP is 96%, you can approximate your expected leftover after the 
              required 10x wagering:
            </p>
            <pre className="bg-gray-100 p-4 rounded mt-2 text-gray-800">
              Expected leftover = Bonus * (RTP)^playthrough
            </pre>
            <p className="text-gray-700 mt-4 leading-relaxed">
              This formula is a simplified model, assuming you can treat each wager as 
              independent and that you’re not changing wager sizes drastically. 
              Real results vary, but the concept illustrates how a higher playthrough 
              requirement compounds the house edge and reduces your net.
            </p>
          </section>

          {/* Section 4: The Impact of Volatility */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">4. Volatility &amp; Variance</h2>
            <p className="text-gray-700 leading-relaxed">
              <strong>Volatility</strong> (or variance) measures how widely results can swing 
              relative to the average return. A high-volatility slot may offer a 96% RTP yet 
              give you fewer, bigger wins, while a low-volatility slot might dole out smaller, 
              more frequent payouts. In mathematical terms, the <em>variance (σ²)</em> for a 
              given wager can significantly influence your short-term experience—possibly 
              overshadowing RTP. Over many trials, RTP dominates, but in the short term, 
              large swings can lead to either quick big wins or a rapid loss of bankroll.
            </p>
          </section>

          {/* Section 5: Combining the Concepts for Profitable Offers */}
          <section>
            <h2 className="text-2xl font-bold text-gray-800 mb-2">5. Combining RTP, Volatility, and Playthrough</h2>
            <p className="text-gray-700 leading-relaxed">
              When deciding whether a bonus is worth “washing,” consider:
            </p>
            <ul className="list-disc ml-6 mt-2 text-gray-700">
              <li><strong>Effective RTP:</strong> Does the game you’re using for playthrough have a high enough RTP to minimize average losses?</li>
              <li><strong>Playthrough Size:</strong> The higher the requirement, the more the house edge compounds.</li>
              <li><strong>Volatility Fit:</strong> High-volatility games can yield big wins that offset further wagering but come with a higher risk of ruin.</li>
              <li><strong>Expected Value vs. Cost:</strong> If the predicted leftover (after meeting playthrough) is greater than the cost of buying into the offer, the deal has a positive EV.</li>
            </ul>
          </section>

          {/* Section 6: Conclusion */}
          <section className="py-6 border-t">
            <h2 className="text-2xl font-bold text-gray-800 mb-2">Conclusion</h2>
            <p className="text-gray-700 leading-relaxed">
              Calculating expected value in social casinos requires a mix of economic theory,
              probability, and real-time data. While RTP drives your long-term net, volatility 
              can cause short-term fluctuations, and playthrough requirements compound the 
              house edge’s impact on your bonus. By choosing high-RTP, lower-volatility games 
              for your playthrough, tracking your results, and carefully assessing each offer’s 
              cost relative to its potential reward, you can shift your EV into positive territory— 
              making social casinos a viable side hustle rather than a gamble.
            </p>
            <p className="text-gray-700 leading-relaxed mt-4">
              Ready to apply these principles? Visit our <Link to="/dashboard" className="text-indigo-600 underline">Dashboard</Link> to start 
              tracking your bonuses and see which casinos give you the most favorable EV.
              And for more strategies on maximizing these expected values, check out our 
              <Link to="/the-method" className="text-indigo-600 underline ml-1">Method</Link>.
            </p>
          </section>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4">
        <div className="max-w-4xl mx-auto text-center">
          <p className="mb-2">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <Link to="/" className="underline">
            Back to Home
          </Link>
        </div>
      </footer>
    </div>
  );
}

export default CalculatedExpectedValue;