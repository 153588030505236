import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

function Glossary() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    supabase.auth.getUser().then(({ data: { user } }) => {
      setUser(user);
    });
  }, []);

  return (
    <div className="min-h-screen bg-white flex flex-col">
      {/* Sticky Gradient Header with User Logic */}
      <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-indigo-600 to-cyan-600 p-4 flex justify-between items-center">
        <h1 className="text-white font-bold text-2xl">Daily Bonus Dashboard</h1>
        <nav className="space-x-4">
          <Link to="/" className="text-white hover:text-gray-200">
            Home
          </Link>
          {user ? (
            <Link to="/dashboard" className="text-white hover:text-gray-200">
              Dashboard
            </Link>
          ) : (
            <>
              <Link to="/login" className="text-white hover:text-gray-200">
                Log In
              </Link>
              <Link
                to="/signup"
                className="bg-white text-indigo-700 px-4 py-2 rounded hover:bg-gray-100 transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </nav>
      </header>

      {/* Main Content */}
      <main className="p-8 max-w-4xl mx-auto">
        <h1 className="text-3xl font-extrabold text-gray-800 mb-6">
          Glossary of Terms
        </h1>
        <p className="text-gray-600 mb-8">
          Below are common terms and concepts related to churning online social 
          casinos. Understanding these will help you navigate promotions, bonuses,
          and manage your bankroll responsibly.
        </p>

        <div className="space-y-6">
          <div>
            <h2 className="text-xl font-bold text-indigo-600">Return to Player (RTP)</h2>
            <p className="text-gray-700 mt-2">
              The percentage of all the wagered money a casino game will pay back to 
              players over time. A higher RTP means better long-term returns for 
              the player. For example, a game with 95% RTP will pay out $95 on average 
              for every $100 bet.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">Washing</h2>
            <p className="text-gray-700 mt-2">
              A strategy where promotional credits are cycled through a high-RTP game 
              in order to turn them into withdrawable funds. While 
              potentially profitable, it’s also risky and can encourage more gambling.
            </p>
            <p className="text-gray-700 mt-2">
              <strong>Overwashing:</strong> This term refers to playing with already redeemable currency.
              This should be strongly avoided as the house always wins. Overwashing also exponentially decreases
              the rtp and your profitability.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">Churning</h2>
            <p className="text-gray-700 mt-2">
              A term borrowed from credit card or bank account bonus hunting, where 
              you repeatedly sign up or take advantage of various promos to accumulate 
              bonuses. In social casinos, it means playing across multiple sites to 
              collect many daily freebies, welcome offers, and promotions.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">Play Through Requirement</h2>
            <p className="text-gray-700 mt-2">
              The amount you must wager before bonus funds become withdrawable. For 
              instance, a 10x playthrough on a $10 bonus means you must wager at least 
              $100 total before cashing out. This has an exponential impact on rtp. For example an 
              rtp of 95% with a 10x playthrough would be 95%^10 = 59.87% rtp.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">Sweeps Coins vs Gold Coins</h2>
            <p className="text-gray-700 mt-2">
              Sweepstakes/social casinos use virtual currencies, often called Gold Coins and Sweeps Coins
              but they can be called something else on different sites. “Gold Coins” hold no 
              real monetary value, used for fun or extended play. "Sweeps Coins" technically cannot be purchased,
              they are only received as a bounus for purchasing Gold Coins, that is the loophole that the sites exploit.
              These sweeps coins can be redeemed for real monetary value. 
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">Sweepstakes Casino</h2>
            <p className="text-gray-700 mt-2">
              Social casinos are online platforms or apps where you can play casino-style games 
              (like slots, poker, blackjack, and roulette) using virtual currencies rather than 
              real money. They focus heavily on the social aspects, often featuring chat functions, 
              friend lists, and leaderboards. While the primary currency has no real-world value, 
              some platforms utilize special sweepstakes coins or offer redemption options that 
              allow players to convert winnings into real prizes or cash.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">Variance</h2>
            <p className="text-gray-700 mt-2">
              A measure of how spread out the results of a game are. A high-variance 
              game might pay out less frequently but can yield bigger wins, while a 
              low-variance game pays smaller amounts more often. Understanding variance 
              helps in selecting games that match your risk tolerance. Low variance
              games are better for churning as they generally have a higher RTP and are more consistent.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">Bankroll Management</h2>
            <p className="text-gray-700 mt-2">
              The practice of setting limits and managing the amount of money set 
              aside for gambling. Good bankroll management helps ensure you don’t 
              overspend or chase losses.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">Daily Bonus</h2>
            <p className="text-gray-700 mt-2">
              A free promotion offered by many social casinos, often awarded every 24 
              hours or at a specific time.
            </p>
          </div>

          <div>
            <h2 className="text-xl font-bold text-indigo-600">Promo Banning</h2>
            <p className="text-gray-700 mt-2">
              Promo banning is when a casino will restrict a user from purchasing special promotions with an edge which the user can use to flip a profit. They will ban a user from seeing these offers only after the user has a long history of making money from the casino. Being promo banned is a big compliment to what we are trying to do.
            </p>
          </div>
        </div>
      </main>

      {/* Footer (Updated to match Home.js) */}
      <footer className="bg-gradient-to-r from-indigo-600 to-cyan-600 text-white p-4 mt-auto">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <p className="mb-2 md:mb-0">
            © {new Date().getFullYear()} DailyBonusDashboard. All rights reserved.
          </p>
          <div className="space-x-4">
            <Link to="/signup" className="underline">
              Sign Up
            </Link>
            <Link to="/login" className="underline">
              Log In
            </Link>
            <Link to="/dashboard" className="underline">
              Dashboard
            </Link>
            <Link to="/privacy-policy" className="underline">
              Privacy Policy
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Glossary;