import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import Home from './Home';
import Signup from './Signup';
import Login from './Login';
import Dashboard from './Dashboard';
import CasinoTricks from './CasinoTricks';
import Glossary from './Glossary';
import GettingStarted from './GettingStarted';
import FAQ from './FAQ';
import CasinoReviews from './CasinoReviews';
import CrownCoinsCasinoReview from './CrownCoinsCasinoReview';
import TheMethod from './TheMethod';
import WhyRTPMatters from './blog/WhyRTPMatters';
import AvoidingCommonMistakes from './blog/AvoidingCommonMistakes';
import ProfitabilityRankingsExplained from './blog/ProfitabilityRankingsExplained';
import CalculatedExpectedValue from './blog/CalculatedExpectedValue';
import PrivacyPolicy from './PrivacyPolicy';
import ScrollToTop from './ScrollToTop';

// NEW IMPORT FOR GRAVITY BLACKJACK BLOG POST
import GravityBlackjackOptimalStrategy from './blog/GravityBlackjackOptimalStrategy';

// NEW IMPORT FOR BLACKJACK CALCULATOR PAGE
import BlackjackCalculator from './BlackjackCalculator';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />

        {/* New route for Casino Tricks page */}
        <Route path="/casino-tricks" element={<CasinoTricks />} />

        {/* New route for Glossary of Terms page */}
        <Route path="/glossary" element={<Glossary />} />

        {/* New route for Getting Started page */}
        <Route path="/getting-started" element={<GettingStarted />} />

        {/* FAQ page */}
        <Route path="/faq" element={<FAQ />} />

        {/* Casino Reviews page */}
        <Route path="/casino-reviews" element={<CasinoReviews />} />

        {/* Crown Coins Casino Detailed Review */}
        <Route
          path="/casino-reviews/crown-coins-casino"
          element={<CrownCoinsCasinoReview />}
        />

        {/* The Method page */}
        <Route path="/the-method" element={<TheMethod />} />

        {/* Why RTP Matters page */}
        <Route path="/blog/why-rtp-matters" element={<WhyRTPMatters />} />

        {/* NEW ROUTE FOR AVOIDING COMMON MISTAKES */}
        <Route
          path="/blog/avoid-common-mistakes"
          element={<AvoidingCommonMistakes />}
        />

        {/* NEW ROUTE FOR PROFITABILITY RANKINGS EXPLAINED */}
        <Route
          path="/blog/profitability-rankings-explained"
          element={<ProfitabilityRankingsExplained />}
        />

        {/* CALCULATED EXPECTED VALUE */}
        <Route
          path="/blog/calculated-expected-value"
          element={<CalculatedExpectedValue />}
        />

        {/* Privacy Policy Page */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* NEW ROUTE FOR GRAVITY BLACKJACK */}
        <Route
          path="/blog/gravity-blackjack-optimal-strategy"
          element={<GravityBlackjackOptimalStrategy />}
        />

        {/* NEW ROUTE FOR BLACKJACK CALCULATOR */}
        <Route
          path="/blackjack-calculator"
          element={<BlackjackCalculator />}
        />
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;